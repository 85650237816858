import React, { useState, useEffect, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { useParams, useHistory } from "react-router-dom";
import ReactGA from "react-ga";

import emojiRegex from "emoji-regex/RGI_Emoji.js";

//import SubmissionContext from "../Shared/SubmissionContext";
import Navigation from "../Navigation/Navigation";
import HelpDialog from "../HelpDialog/HelpDialog";
import clientNumberExample from "./studylink-client-number-example.png";
import { CreateSubmission, GetSubmission, UpdateSubmission, PingWithExceptions } from "../Shared/ApiAccess";
import ErrorDialog from "../ErrorDialog/ErrorDialog";

const useStyles = makeStyles(() => ({
    controlContainer: {
        marginBottom: "0.5rem",
    },
    help: {
        marginTop: "1rem",
        height: "3.5rem",
        alignItems: "flex-start",
    },
    exampleImage: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    clientNumberContainer: {
        display: "flex",
    },
    clientNumber: {
        flexGrow: "1",
    },
}));

const ClientDetails = () => {
    const classes = useStyles();
    const history = useHistory();
    const email1Ref = useRef(null);
    const email2Ref = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const clientNumberRef = useRef(null);

    const [submission, setSubmission] = useState({});
    const [email1, setEmail1] = useState("");
    const [email1Error, setEmail1Error] = useState("");
    const [email1Touched, setEmail1Touched] = useState(false);
    const [email2, setEmail2] = useState("");
    const [email2Error, setEmail2Error] = useState("");
    const [email2Touched, setEmail2Touched] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [firstNameTouched, setFirstNameTouched] = useState(false);
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [lastNameTouched, setLastNameTouched] = useState(false);
    const [clientNumber, setClientNumber] = useState("");
    const [clientNumberError, setClientNumberError] = useState("");
    const [clientNumberTouched, setClientNumberTouched] = useState(false);
    const [showValidation, setShowValidation] = useState(false);

    const [helpOpen, setHelpOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);

    let { id } = useParams();

    const saveDetails = async (email, firstName, lastName, clientNumber) => {
        var sub = {
            ...submission,
            UserEmail: email,
            FirstName: firstName,
            LastName: lastName,
            ClientNumber: clientNumber,
        };
        let result;

        try {
            if (sub.SubmissionId) {
                result = await UpdateSubmission(sub);
            } else {
                result = await CreateSubmission(sub);
                history.replace("/studentDetails/" + sub.SubmissionId);
            }

            setSubmission(result);
            history.push("/upload/" + sub.SubmissionId);
        } catch (error) {
            setErrorMessage(error.message);
            setErrorOpen(true);
        }
    };

    const clearErrorCallback = () => {
        if (errorMessage === "You need to log in before you can access this page") {
            history.push("/");
        } else {
            history.push("/home");
        }
        setErrorOpen(false);
        //     if (errorMessage.includes("You cannot access a Completed submission.") || errorMessage.includes("The submission record does not exist.")) {
    };

    const handleNext = () => {
        setClientNumberTouched(true);
        setFirstNameTouched(true);
        setLastNameTouched(true);
        setEmail1Touched(true);
        setEmail2Touched(true);

        if (email1Error || email2Error || firstNameError || lastNameError || clientNumberError) {
            setShowValidation(true);
        } else {
            saveDetails(email1, firstName, lastName, clientNumber);
        }
    };

    useEffect(() => {
        const getDetails = async () => {
            ReactGA.modalview("/clientDetails");

            let sub;

            try {
                if (id == "new") {
                    sub = {
                        ApplicationTypeDescription: "Student Loan",
                        ApplicationTypeDescriptionId: 10,
                        FormTypeDescription: "Student Loan Application Form",
                        FormTypeDescriptionId: 10,
                        Archived: "N",
                        ClientNumber: "",
                        Evidence: [],
                        FirstName: "",
                        LastName: "",
                        OtherEvidence: [],
                        Status: "Draft",
                        UploadedFiles: [],
                        UserEmail: "",
                    };
                    await PingWithExceptions();
                } else {
                    sub = await GetSubmission(id);

                    if (sub.Status !== "Draft") {
                        setErrorMessage("You cannot access a Completed submission.");
                        setErrorOpen(true);
                    }
                }
            } catch (error) {
                setErrorMessage(error.message);
                setErrorOpen(true);
                return;
            }
            setSubmission(sub);

            setEmail1(sub.UserEmail);
            setEmail2(sub.UserEmail);
            setFirstName(sub.FirstName);
            setLastName(sub.LastName);
            setClientNumber(sub.ClientNumber);
            if (sub.UserEmail || sub.ClientNumber) {
                setClientNumberTouched(true);
                setFirstNameTouched(true);
                setLastNameTouched(true);
                setEmail1Touched(true);
                setEmail2Touched(true);
            }
        };
        getDetails();
    }, [id]);

    useEffect(() => {
        const emojiEmail = emojiRegex();
        if (email1 === "") {
            setEmail1Error("Please enter an email address.");
        } else if (email1.length > 100) {
            setEmail1Error("The email address cannot be longer than 100 characters.");
        } else if (!/\S+@\S+\.\S+/.test(email1)) {
            setEmail1Error("Please provide a valid email address.");
        } else if (emojiEmail.exec(email1)) {
            setEmail1Error("The email address cannot contain emoji characters.");
        } else {
            setEmail1Error("");
        }

        if (email2 === "") {
            setEmail2Error("Please enter the confirmation email address.");
        } else if (email2 !== email1) {
            setEmail2Error("The email fields do not match.");
        } else {
            setEmail2Error("");
        }

        const emojiFirstName = emojiRegex();
        if (firstName.length > 50) {
            setFirstNameError("The first name cannot be longer than 50 characters.");
        } else if (/\d/.test(firstName)) {
            setFirstNameError("The first name cannot contain digits.");
        } else if (emojiFirstName.exec(firstName)) {
            setFirstNameError("The first name cannot contain emoji characters.");
        } else {
            setFirstNameError("");
        }

        const emojiLastName = emojiRegex();
        if (lastName.length > 50) {
            setLastNameError("The last name cannot be longer than 50 characters.");
        } else if (/\d/.test(lastName)) {
            setLastNameError("The last name cannot contain digits.");
        } else if (emojiLastName.exec(lastName)) {
            setLastNameError("The last name cannot contain emoji characters.");
        } else {
            setLastNameError("");
        }

        if (clientNumber === "") {
            setClientNumberError("Please enter the client number.");
        } else if (!validateClientNumber(clientNumber)) {
            setClientNumberError("Invalid client number. Number must be 9 digits long.");
        } else {
            setClientNumberError("");
        }
    }, [email1, email2, firstName, lastName, clientNumber]);

    const validateClientNumber = (swn) => {
        if (typeof swn === "undefined") {
            return false;
        }

        if (isNaN(swn)) {
            return false;
        }

        if (swn.length !== 9) {
            return false;
        }

        if (swn === "000000000") {
            return false;
        }

        var check = 11 - ((swn[0] * 8 + swn[1] * 7 + swn[2] * 9 + swn[3] * 10 + swn[4] * 6 + swn[5] * 3 + swn[6] * 4 + swn[7] * 2) % 11);

        if (check === 11) {
            check = 0;
        }

        if (check === 10) {
            check = 11 - ((swn[0] * 3 + swn[1] * 4 + swn[2] * 2 + swn[3] * 10 + swn[4] * 5 + swn[5] * 8 + swn[6] * 7 + swn[7] * 9) % 11);

            if (check === 11) {
                check = 0;
            }
        }

        return String(check) === swn[8];
    };

    return (
        <div style={{ height: "100%" }}>
            <Navigation stepIndex={0}>
                <form
                    name="userDetails"
                    id="userDetails"
                    autoComplete="off"
                    // onSubmit={handleSubmit}
                >
                    <Typography variant="h2">This is the first step to upload and submit your documents to StudyLink.</Typography>

                    <Grid container className={classes.controlContainer}>
                        <Grid item xs={12}>
                            <Typography variant="h3">Please enter your email address below</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="email1"
                                name="email1"
                                inputRef={email1Ref}
                                label="Your email address"
                                //     type="email"
                                variant="outlined"
                                margin="normal"
                                value={email1}
                                onChange={(e) => setEmail1(e.target.value)}
                                onBlur={() => {
                                    if (!email1Touched) setEmail1Touched(true);
                                }}
                                error={email1Touched && email1Error !== ""}
                                helperText={email1Touched && email1Error}
                                fullWidth
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="email2"
                                name="email2"
                                inputRef={email2Ref}
                                label="Confirm your email address"
                                margin="normal"
                                variant="outlined"
                                value={email2}
                                onChange={(e) => setEmail2(e.target.value)}
                                onBlur={() => {
                                    if (!email2Touched) setEmail2Touched(true);
                                }}
                                error={email2Touched && email2Error !== ""}
                                helperText={email2Touched && email2Error}
                                fullWidth
                                autoComplete="email"
                            />
                        </Grid>
                        {/* <Grid item xs={12} className={classes.verticalSpacer} /> */}
                        <Grid item xs={12}>
                            <Typography variant="h3">Please enter the Student details required below</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="firstname"
                                name="firstname"
                                inputRef={firstNameRef}
                                label="Student's first name"
                                margin="normal"
                                variant="outlined"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                onBlur={() => {
                                    if (!firstNameTouched) setFirstNameTouched(true);
                                }}
                                error={firstNameTouched && firstNameError !== ""}
                                helperText={firstNameTouched && firstNameError}
                                fullWidth
                                autoComplete="given-name"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="lastname"
                                name="lastname"
                                inputRef={lastNameRef}
                                label="Student's last name"
                                margin="normal"
                                variant="outlined"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                onBlur={() => {
                                    if (!lastNameTouched) setLastNameTouched(true);
                                }}
                                error={lastNameTouched && lastNameError !== ""}
                                helperText={lastNameTouched && lastNameError}
                                fullWidth
                                autoComplete="family-name"
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.clientNumberContainer}>
                            <TextField
                                id="clientnumber"
                                name="clientnumber"
                                inputRef={clientNumberRef}
                                label="Student's client number"
                                margin="normal"
                                variant="outlined"
                                value={clientNumber}
                                onChange={(e) => setClientNumber(e.target.value)}
                                onBlur={() => {
                                    if (!clientNumberTouched) setClientNumberTouched(true);
                                }}
                                error={clientNumberTouched && clientNumberError !== ""}
                                helperText={clientNumberTouched && clientNumberError}
                                // fullWidth
                                className={classes.clientNumber}
                                autoComplete="decimal"
                            />

                            <Tooltip title="help">
                                <IconButton aria-label="help" color="primary" className={classes.help} onClick={() => setHelpOpen(true)}>
                                    <HelpIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant="body1">
                                If you are submitting documents for a student’s application (e.g. Parent or Partner forms), you will need to provide the
                                student’s client number along with their legal name.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4} sm={2}>
                            <Button
                                variant="contained" // disabled={activeStep === 0}
                                onClick={() => history.push("/home")}
                                color="secondary"
                                fullWidth
                                disableRipple
                            >
                                {id == "new" ? "Cancel" : "Close"}
                            </Button>
                        </Grid>
                        <Grid item xs={4} sm={8}></Grid>
                        <Grid item xs={4} sm={2}>
                            <Button variant="contained" color="primary" onClick={handleNext} fullWidth disableRipple>
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Navigation>

            {/* Validation */}
            <Dialog open={showValidation} aria-labelledby="validation-dialog-title" aria-describedby="validation-dialog-description">
                <DialogTitle id="validation-dialog-title">Errors</DialogTitle>
                <DialogContent>
                    <DialogContentText id="validation-dialog-description">
                        <Typography variant="body1">
                            The following error(s) need fixing:
                            <ul>
                                {email1Error && <li> {email1Error}</li>}
                                {email2Error && <li> {email2Error}</li>}
                                {firstNameError && <li> {firstNameError}</li>}
                                {lastNameError && <li> {lastNameError}</li>}
                                {clientNumberError && <li> {clientNumberError}</li>}
                            </ul>
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="validation-close" color="primary" variant="contained" autoFocus disableRipple onClick={() => setShowValidation(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* help dialog */}
            <HelpDialog
                showHelp={helpOpen}
                clearHelpCallback={() => {
                    setHelpOpen(false);
                }}
            >
                <div className={classes.exampleImage}>
                    <img width="100%" src={clientNumberExample} alt="" />
                </div>

                <Typography variant="body1">
                    Your client number can be found on your Community Services Card if you have one or on the top right hand corner of your StudyLink letter.
                </Typography>
            </HelpDialog>

            <ErrorDialog errorMessage={errorMessage} open={errorOpen} clearErrorCallback={clearErrorCallback} />
        </div>
    );
};

export default ClientDetails;
