import React, { useEffect, useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

import { useHistory } from "react-router-dom";
import useInterval from "../Shared/useInterval";

import configuration from "../Shared/Config";
import { Ping } from "../Shared/ApiAccess";

import axios from "axios";

function IdleCheck() {
    const history = useHistory();
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        axios.interceptors.response.use(function (response) {
            localStorage.setItem("timeout", Date.now());

            return response;
        });

        localStorage.setItem("timeout", Date.now());
    }, []);

    useInterval(() => {
        const storedTimestamp = parseInt(localStorage.getItem("timeout"));

        if (
            location.pathname.toLowerCase() === "/home" ||
            location.pathname.toLowerCase().startsWith("/studentdetails/") ||
            location.pathname.toLowerCase().startsWith("/upload/") ||
            location.pathname.toLowerCase().startsWith("/submit/")
        ) {
            setShowWarning(Date.now() > storedTimestamp + configuration.idleTimeout);
            if (Date.now() > storedTimestamp + configuration.continueTimeout) {
                setShowWarning(false);
                history.push("/studylink/connect/logout");
            }
        } else {
            setShowWarning(false);
        }
    }, configuration.idleTick);

    const closeWarning = () => {
        // ping will reset the timer too
        Ping();
        setShowWarning(false);
    };

    return (
        <div>
            <Dialog open={showWarning} onClose={closeWarning} aria-labelledby="warning-dialog-title" aria-describedby="warning-dialog-description">
                <DialogTitle id="warning-dialog-title">Login timeout</DialogTitle>
                <DialogContent id="warning-dialog-description">
                    <DialogContentText id="alert-dialog-description">
                        Your login will timeout in one minute. Please click continue if you want to stay logged in.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="continue" onClick={closeWarning} color="primary" variant="contained" autoFocus disableRipple>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default IdleCheck;
