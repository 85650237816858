import React, { useEffect, useState, useRef } from "react";
import "@fontsource/inter";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import { useLocation, useHistory } from "react-router-dom";

//import "../realme/css/realme.css";
import Footer from "../Footer/Footer";
import ErrorDialog from "../ErrorDialog/ErrorDialog";
//import HelpDialog from "../HelpDialog/HelpDialog";
import connectImage from "./connectlogo.png";
import studylinkImage from "./studylink-logo.svg";
import bannerBackground from "./banner-bg.svg";
import titleBackground from "./title-background.svg";
import realmeLogo from "../RealMe/RealMe Face Symbol_White.svg";
import upArrow from "./up-arrow.svg";
import dart from "./dart.svg";
import circle from "./circle.svg";
import tick from "./tick.svg";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        minWidth: "305px",
        margin: "auto",
    },
    titleContainer: {
        display: "flex",
        alignItems: "center",
    },
    divider: {
        flexGrow: 1,
    },
    line1: {
        height: "2px",
        backgroundColor: "#ccdf87",
    },
    studylinkLogoLink: {
        "&:focus": {
            outline: "1.5px solid black",
            outlineOffset: "-1px",
        },
    },
    studylinkLogo: {
        width: "317px",
        height: "60px",
    },
    bannerimg: {
        backgroundSize: "cover",
        backgroundImage: "url(" + bannerBackground + ")",
        backgroundOrigin: "content-box",
        backgroundRepeat: "no-repeat",
        flexGrow: "1",
        height: "120px",
    },
    banner: {
        height: "123px",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
    },
    line2: {
        height: "7px",
        backgroundColor: "#005a9c",
    },

    pageTitle: {
        fontWeight: "700",
        fontSize: "2rem",
        textAlign: "center",
        marginBottom: "1rem",
        width: "100%",
    },
    siteHeader: {
        width: "100%",
        backgroundRepeat: "repeat",
        backgroundOrigin: "content-box",
        backgroundImage: "url(" + titleBackground + ")",
        backgroundPosition: "-40px -10px",
        backgroundClip: "content-box",
    },
    framing: {
        padding: "2rem 0px",
    },
    pageIntro: {
        width: "100%",
        fontSize: "1.5rem",
        fontWeight: "400",
        textAlign: "center",
        lineHeight: "1.5rem",
        marginBottom: "1rem",
    },
    greyBack: {
        backgroundColor: "#f2f2f2",
    },
    sectionHeading: {
        color: "#005a9c",
        fontSize: "1.5rem",
        fontWeight: "500",
        marginBottom: "0.75rem",
    },
    sectionBody: {
        fontSize: "1rem",
        marginBottom: "1rem",
    },
    sectionBodyThreeEasy: {
        fontSize: "1rem",
        marginBottom: ".5rem",
        marginTop: "0.5rem",
    },
    icon: {
        margin: "0rem 1rem",
    },
    posImg: {
        height: "30px",
        width: "30px",
        backgroundImage: "url(" + circle + ")",
        fontSize: "20px",
        backgroundSize: "30px",
        textAlign: "center",
        fontWeight: "bold",
        marginRight: "30px",
        marginBottom: "0px",
    },
    tickImg: {
        height: "60px",
        width: "60px",
    },
    listBorder: {
        "& li": {
            marginBottom: "2px",
        },
        marginTop: "4px",
    },
    threeSteps: {
        display: "flex",
        alignItems: "center",
    },
    screenreaderText: {
        position: "absolute",
        left: "-999px",
        width: "1px",
        height: "1px",
        top: "auto",
        "&:focus": {
            color: "black",
            display: "inline-block",
            height: "auto",
            width: "auto",
            position: "static",
            margin: "auto",
        },
    },
    stepsList: {
        listStyleType: "none",
        paddingLeft: "0px",
    },
    redAlert: {
        backgroundColor: "red",
        color: "white",
        textAlign: "center",
        margin: "20px",
        padding: "50px",
    },
    redAlertText: {
        color: "white",
    },
    realmeLogoStyle: {
        width: "40px",
        margin: "-8px 2px -8px -12px",
    },
    realmeFont: {
        fontFamily: "Inter",
        color: "black",
        fontSize: "16px",
    },
    realmeButton: {
        "&:hover": {
            backgroundColor: "#FF2C00",
        },
        color: "white",
        backgroundColor: "#D64309",
        fontSize: "12px",
        fontFamily: "Inter",
        borderRadius: "15px",
        width: "170px",
        justifyContent: "left",
    },
    realmeBox: {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#D64309",
        width: "250px",
        padding: "40px 20px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "30px",
        backgroundColor: "white",
    },
}));

function LandingPage() {
    const classes = useStyles();
    let location = useLocation();
    let history = useHistory();
    const headingRef = useRef(null);

    const [errorMessage, setErrorMessage] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);
    //   const [helpOpen, setHelpOpen] = useState(false);

    useEffect(() => {
        try {
            var search = location.search.substring(1);
            var obj = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
                return key === "" ? value : decodeURIComponent(value);
            });
            var error = obj.routeException;
            var errMsg = null;
            if (error.indexOf("The user has cancelled entering self-asserted information") > -1) {
                errMsg = null;
            } else if (error.indexOf("RealMe login service session timeout") > -1) {
                errMsg = "Your RealMe session has timed out – please try again";
            } else if (error.indexOf("Error: Invalid signature") > -1) {
                errMsg =
                    "RealMe reported a serious application error. Please try again later. If the problem persists, please contact the StudyLink Contact Centre on 0800 88 99 00.";
            } else if (error.indexOf("The RealMe login service IdP is not available") > -1) {
                errMsg =
                    "RealMe reported a serious application error with the message noAvailableIDP. Please try again later. If the problem persists, please contact the StudyLink Contact Centre on 0800 88 99 00.";
            } else if (error.indexOf("RealMe login service Internal Error") > -1) {
                errMsg =
                    "RealMe was unable to process your request due to a RealMe internal error. Please try again. If the problem persists, please contact the StudyLink Contact Centre on 0800 88 99 00.";
            } else if (error.indexOf("The request is not supported by the RealMe login service") > -1) {
                errMsg =
                    "RealMe reported a serious application error with the message requestUnsupported. Please try again later. If the problem persists, please contact the StudyLink Contact Centre on 0800 88 99 00.";
            } else if (error.indexOf("User cancelled login") > -1) {
                errMsg = "You have chosen to leave the RealMe login screen without completing the login process";
            } else if (error.indexOf("You need to log in before you can access this page") > -1) {
                errMsg = "You need to log in before you can access this page";
            } else {
                errMsg =
                    "RealMe reported a serious application error. Please try again later. If the problem persists, please contact the StudyLink Contact Centre on 0800 88 99 00.";
            }
            if (errMsg !== null) {
                setErrorMessage(errMsg);
                setErrorOpen(true);
            }
        } catch {
            // hide lookup errors etc from incrrectly formed url params
        }
        history.replace("/");
    }, [location.search, history]);

    return (
        <Container maxWidth="lg" className={classes.root}>
            <header className={classes.titleContainer}>
                <img src={connectImage} alt="Connect with StudyLink" height="25px" width="115px" />

                {/* <div className={classes.divider}></div>

        <div
          className="realme_widget realme_secondary_login realme_theme_dark realme-custom float-right realme-header-widge"
          role="img"
          aria-label="RealMe"
        >
          <a
            href="/studyLink/connect"
            className="realme_login realme_pipe"
            title="RealMe Login"
          >
            Login <span className="realme_icon_link"></span>
          </a>
          <div className="realme_popup_position">
            <a
              id="popup_trigger"
              href="#"
              onClick={() => setHelpOpen(true)}
              data-toggle="modal"
              className="link whats_realme"
            >
              ?
            </a>
          </div>
        </div> */}
            </header>
            <main>
                <div className={classes.line1}></div>

                <div className={classes.banner}>
                    <div className="col-auto align-self-center studylink-container">
                        <Link href="https://www.studylink.govt.nz/" className={classes.studylinkLogoLink}>
                            <img className={classes.studylinkLogo} src={studylinkImage} alt="Go to StudyLink homepage" />
                        </Link>
                    </div>
                    <div className={classes.bannerimg}></div>
                </div>

                <div className={classes.line2}></div>

                <div className={classes.siteHeader}>
                    <div className={classes.framing}>
                        <div>
                            {/* <div className={classes.redAlert}>
                                <Typography variant="body1" className={classes.redAlertText}>
                                    <b>RealMe update this weekend</b>
                                </Typography>
                                <Typography variant="body1" className={classes.redAlertText}>
                                    There&apos;s a RealMe update this Saturday 19 February, from 8am to 11am. During the update you won&apos;t be able to submit
                                    information through the Connect website.
                                </Typography>
                            </div> */}
                            <Typography variant="h1" className={classes.pageTitle} ref={headingRef} tabindex="-1">
                                Connect with StudyLink
                            </Typography>
                            <Typography variant="body1" className={classes.pageIntro}>
                                Document delivery that’s <strong>secure</strong>, <strong>quick</strong> and <strong>easy</strong>.
                            </Typography>

                            {/* <!-- RealMe: Primary login module --> */}
                            <div className={classes.realmeBox}>
                                <Typography variant="h1" className={classes.realmeFont}>
                                    Log in with RealMe&reg;
                                </Typography>
                                {/* <h2 className="realme_title">Login with RealMe&reg;</h2> */}
                                {/* <p className="realme_info">
                                    To access Connect, you need a RealMe login. If you&rsquo;ve created a RealMe login somewhere else, you can use it here too.
                                </p> */}
                                <Button
                                    className={classes.realmeButton}
                                    id="login"
                                    // onClick={() => {
                                    //     props.confirmCallback(false);
                                    // }}
                                    // color="secondary"
                                    variant="contained"
                                    disableRipple
                                    href="/studyLink/connect"
                                >
                                    <img src={realmeLogo} className={classes.realmeLogoStyle} alt="RealMe" />
                                    Log in
                                </Button>
                                {/* <div className="realme_login_lockup">
                                    <img src={realmeLogo} alt="RealMe" width="42" height="41" />
                                    <div className="realme_btn_margin">
                                        <Link href="/studyLink/connect" className="realme_button">
                                            <span className="realme_button_padding">
                                                Login
                                                <span className="realme_icon_new_window"></span>
                                                <span className={classes.screenreaderText}>with RealMe</span>
                                            </span>
                                            <span className="realme_icon_padlock"></span>
                                        </Link>
                                    </div>
                                </div> */}

                                {/* <div className="realme_popup_position">
                                    <Link
                                        className="js_toggle_popup whats_realme"
                                        href="#"
                                        onClick={() => setHelpOpen(true)}
                                        data-toggle="modal"
                                        underline="always"
                                    >
                                        What&rsquo;s RealMe?
                                    </Link>
                                </div> */}
                            </div>
                            {/* <!-- End RealMe: Primary login module --> */}
                        </div>
                        <div className="col"></div>
                    </div>
                </div>

                <div className={classes.greyBack + " " + classes.framing}>
                    <Grid container>
                        <Grid item xs={1} lg={1}></Grid>

                        <Grid item xs={10} lg={7}>
                            <Typography variant="h2" className={classes.sectionHeading}>
                                Upload and send
                            </Typography>
                            <Typography variant="body1" className={classes.sectionBody}>
                                Connect makes it easy to send applications, evidence and information directly to StudyLink.
                            </Typography>
                            <Typography variant="body1" className={classes.sectionBody}>
                                Parents and partners can support a student&apos;s application by sending documents from their own secure RealMe account.
                                It&apos;s a great option if there are details they only want StudyLink to see.
                            </Typography>
                        </Grid>
                        <Grid item xs={1} lg={true}></Grid>
                        <Grid item xs={1} lg={1}></Grid>
                        <Grid item xs={10} lg={3}>
                            <img src={upArrow} alt="" className={classes.icon} width="50px" height="72px" />
                            <img src={dart} alt="" className={classes.icon} width="75px" height="71px" />
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </div>

                <div className={classes.framing}>
                    <Grid container>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={10}>
                            <div className="">
                                <div className="col">
                                    <Typography variant="h2" className={classes.sectionHeading}>
                                        Three easy steps
                                    </Typography>
                                </div>
                            </div>
                            <ol className={classes.stepsList}>
                                <li>
                                    <Grid container>
                                        <Grid item xs="auto" className={classes.threeSteps}>
                                            <Typography variant="body1" className={classes.posImg} role="presentation">
                                                1
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9} md={11}>
                                            <Typography variant="body1" className={classes.sectionBodyThreeEasy}>
                                                Simply sign up for RealMe.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </li>
                                <li>
                                    <Grid container>
                                        <Grid item xs="auto" className={classes.threeSteps}>
                                            <Typography variant="body1" className={classes.posImg} role="presentation">
                                                2
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9} md={11}>
                                            <Typography variant="body1" className={classes.sectionBodyThreeEasy}>
                                                Add your name and client number (or the student&apos;s name and client number if you&apos;re not the student).
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </li>
                                <li>
                                    <Grid container>
                                        <Grid item xs="auto" className={classes.threeSteps}>
                                            <Typography variant="body1" className={classes.posImg} role="presentation">
                                                3
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9} md={11}>
                                            <Typography variant="body1" className={classes.sectionBodyThreeEasy}>
                                                Upload your documents and submit.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </li>
                            </ol>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </div>

                <div className={classes.greyBack + " " + classes.framing}>
                    <Grid container>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={10}>
                            <div className="row">
                                <div className="col">
                                    <Typography variant="h2" className={classes.sectionHeading}>
                                        About verified copies
                                    </Typography>
                                </div>
                            </div>
                            <div className="row">
                                <Typography variant="body1" className={classes.sectionBody}>
                                    A verified copy is a copy that an approved person has signed to say it&apos;s the same as the original. They must sign, date
                                    and print their name and title on every page. They must also write that it is a true copy of the original.
                                </Typography>
                            </div>
                            <div className="row">
                                <Typography variant="body1" className={classes.sectionBody}>
                                    StudyLink&apos;s list of approved people is on their website. It includes:
                                </Typography>
                                <Grid container>
                                    <Grid item sm={10} xs={12}>
                                        <Typography variant="body1" className={classes.sectionBody} component={"span"}>
                                            <ul className={classes.listBorder}>
                                                <li>A school principal, teacher, careers advisor or guidance counsellor</li>
                                                <li>A member of a Board of Trustees</li>
                                                <li>A StudyLink or Work and Income staff member</li>
                                                <li>A registrar or officer of a tertiary education provider</li>
                                                <li>A medical doctor, police officer, church minister or rector, kaumatua, solicitor or lawyer</li>
                                                <li>A Justice of the Peace (JP)</li>
                                            </ul>
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={2} xs={12}>
                                        <img src={tick} alt="" className={classes.tickImg} />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </div>
            </main>
            <Footer />

            {/* <script type="text/javascript" src="../realme/js/jquery.js"></script>
            <script type="text/javascript" src="../realme/js/realme.js"></script> */}

            {/* Realme Help */}
            {/* <HelpDialog showHelp={helpOpen} clearHelpCallback={() => setHelpOpen(false)}>
                <Typography variant="h2">To use this service you now need a RealMe login</Typography>
                <Typography variant="body1">
                    <b>RealMe</b> is a service from the New Zealand Government and New Zealand Post that includes a single login, letting you use one username
                    and password to access a wide range of services online.
                </Typography>
                <Typography variant="h2">How do I create a Connect account?</Typography>
                <Typography variant="body1">
                    You can create a Connect account and digitally submit your StudyLink applications and documents by simply logging in to the service using
                    your RealMe username and password.
                </Typography>
                <Typography variant="h2">What can I do if I don’t have a RealMe login?</Typography>
                <Typography variant="body1">
                    Simply click on the “Create your RealMe login now” link on the Connect login page and complete the necessary steps. When you are finished
                    you will be returned to the Connect site to login.
                </Typography>
                <Typography variant="h2">What if I can’t remember my RealMe username or password?</Typography>
                <Typography variant="body1">
                    You may reset your username or password by clicking the ‘Forgot Username” or &apos;Forgot password?&apos; link on the login page of Connect.
                </Typography>
                <Typography variant="h2">How do I change my RealMe password?</Typography>
                <Typography variant="body1">
                    To change any of your login details, click on the Manage my RealMe link once you have logged in to Connect then follow the steps. This can
                    be found on the bottom right hand corner.
                </Typography>
            </HelpDialog> */}

            <ErrorDialog errorMessage={errorMessage} open={errorOpen} clearErrorCallback={() => setErrorOpen(false)} />
        </Container>
    );
}

export default LandingPage;
