import { createMuiTheme } from "@material-ui/core";
//import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import "@fontsource/fira-sans";

const MainTheme = createMuiTheme({
    typography: {
        fontFamily: "Fira Sans",
        h1: {
            // fontSize: "24px",
            fontSize: "1.5rem",
            color: "#3d4c5c",
            fontWeight: "bold",
            // marginBottom: "12px",
            marginBottom: "1rem",

            "&:focus": {
                outline: "none",
            },
        },
        h2: {
            // fontSize: "18px",
            fontSize: "1.25rem",
            color: "#3d4c5c",
            fontWeight: "bold",
            // marginBottom: "9px",
            marginBottom: "0.75rem",
        },
        h3: {
            // fontSize: "16px",
            fontSize: "1.25rem",
            // fontWeight: "bold",
            color: "#3d4c5c",
            // marginBottom: "8px",
            marginBottom: "0.75rem",
        },
        // weirdly seems to be used to style the h2 heading for dialogs so copy h2 styles and do not use elsewhere
        h6: {
            // fontSize: "18px",
            fontSize: "1.5rem",
            color: "#3d4c5c",
            fontWeight: "bold",
            // marginBottom: "9px",
            //marginBottom: "0.75rem",
        },
        body1: {
            // fontSize: "16px",
            color: "#3d4c5c",
            lineHeight: "1.5",
            // marginBottom: "8px",
            marginBottom: "0.5rem",
        },
        body2: {
            // fontSize: "16px",
            color: "#3d4c5c",
            lineHeight: "1.5",
        },
        button: {
            // fontSize: "16px",
            textTransform: "none",
        },
    },
    palette: {
        primary: {
            main: "#005a9c",
        },
        secondary: {
            main: "#3d4c5c",
        },
    },
    overrides: {
        // MuiTooltip: {
        //     tooltip: {
        //         fontSize: "1rem",
        //     },
        // },

        // seems like a material bug? copy these from h2
        MuiStep: {
            horizontal: {
                paddingLeft: "5px",
                paddingRight: "5px",
            },
        },
        MuiAccordion: {
            root: {
                background: "#fafafa", //theme.palette.grey[50],
                marginBottom: "0.5rem",
            },
        },

        MuiAccordionDetails: {
            root: {
                borderStyle: "solid",
                borderColor: "#e0e0e0", //theme.palette.grey[300],
                borderWidth: "1px 0px 0px 0px",
            },
        },

        MuiFormControlLabel: {
            label: {
                marginBottom: "0px",
            },
        },

        MuiStepper: {
            root: {
                padding: "0px",
                background: "inherit",
            },
        },

        MuiContainer: {
            root: {
                height: "100%!important",
            },
        },

        MuiInputLabel: {
            shrink: {
                // fontSize: "16px",
            },
        },

        MuiFormHelperText: {
            root: {
                "&$error": {
                    fontSize: "0.875rem",
                    color: "#D5190B",
                },
            },
        },

        MuiFormLabel: {
            root: {
                "&$error": {
                    color: "#D5190B",
                },
            },
        },
        MuiButton: {
            root: {
                "&:focus": {
                    outline: "1.5px solid white",
                    outlineOffset: "-4px",
                },
            },
        },
        MuiLink: {
            root: {
                "&:focus": {
                    outline: "1.5px solid white",
                    outlineOffset: "2px",
                },
            },
        },
    },
});
// .MuiInputLabel-outlined.MuiInputLabel-shrink
export default MainTheme;
