import React, { useCallback, useMemo, useState, useEffect } from "react";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { useTheme } from "@material-ui/core/styles";
import CheckCircle from "@material-ui/icons/CheckCircle";
//import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Link as RouterLink } from "react-router-dom";

import { useDropzone } from "react-dropzone";
import ReactGA from "react-ga";
import { useParams, useHistory } from "react-router-dom";

import ErrorDialog from "../ErrorDialog/ErrorDialog";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import Navigation from "../Navigation/Navigation";
//import SubmissionContext from "../Shared/SubmissionContext";

import {
    //CreateSubmission,
    GetSubmission,
    // UpdateSubmission,
    CreateFile,
    DeleteFile,
    CancelUpload,
    GetCancelTokenSource,
} from "../Shared/ApiAccess";

const useStyles = makeStyles(() => ({
    controlContainer: {
        marginBottom: ".5rem",
    },
    splitPage: {
        marginTop: "1.25remx",
        marginBottom: "0px",
        // height: "350px",
    },
    dropAreaContainer: {
        // height: "100%",
    },
    dropArea: {
        borderWidth: "1px",
        backgroundColor: "#F9F9F9",
        borderColor: "#e0e0e0",
        borderStyle: "solid",
        borderRadius: "0.5rem",
        textAlign: "center",
        // marginBottom: ".5rem",
        alignItems: "center",
        // height: "100%",
        paddingLeft: "2.5rem",
        paddingRight: "2.5rem",
        paddingTop: "2rem",
        paddingBottom: "2rem",
        height: "100%",
        display: "flex",
        // flexDirection: "column",
        justifyContent: "center",
        "&:focus": {
            outline: "1.5px solid black",
            outlineOffset: "0px",
        },
    },
    onlyButton: {
        textAlign: "center",
        // alignItems: "center",
    },
    fileContainer: {
        overflow: "auto",
        listStyleType: "none",
        // maxHeight: "300px",
        paddingLeft: "0px",
    },
    fileContainerSmall: {
        overflow: "auto",
        // height: "280px",
    },
    fileSection: {
        marginBottom: "0px",
    },
    errorStyle: {
        fontSize: "0.875rem",
        paddingBottom: ".5rem",
    },
    centerText: {
        justifyContent: "center",
    },
}));

function UploadDocuments() {
    const maxFilenameLength = 100;
    const classes = useStyles();
    const history = useHistory();
    const [cancelTokenSource, setCancelTokenSource] = useState(null);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressName, setProgressName] = useState("");
    const [submission, setSubmission] = useState({});
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [activeFileId, setActiveFileId] = useState();
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);

    //const [fileMessage, setFileMessage] = useState([]);

    //const bigScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

    const onDrop = useCallback(
        async (acceptedFiles, fileRejections) => {
            var errs = [];

            if (fileRejections.length > 10) {
                errs = [{ name: "", err: "No more than 10 files can be uploaded at a time." }];
            }

            if (errs.length === 0) {
                errs = fileRejections.map(({ file, errors }) => ({ name: file.path, err: decodeError(errors[0].code) }));
            }

            setOpenBackdrop(true);
            for (const file of acceptedFiles) {
                try {
                    if (/[><\\/?:|"%*]/.test(file.name)) {
                        throw new Error('Filenames cannot contain any of the following characters / \\ ? % * : | " < >');
                    }
                    if ((file.name.match(/\./g) || []).length > 1) {
                        throw new Error("Filenames can only contain a single . character");
                    }
                    if (file.name.length > maxFilenameLength) {
                        throw new Error("The entire filename, including the period and extension, cannot exceed " + maxFilenameLength + " characters");
                    }

                    setSuccess(false);
                    setProgressName(file.name);

                    let source = GetCancelTokenSource();
                    setCancelTokenSource(source);

                    const res = await CreateFile(submission, file, progressCallback, source);

                    setSubmission(res);
                    setSuccess(true);
                    await new Promise((r) => setTimeout(r, 2000));
                } catch (error) {
                    errs.push({ name: file.name, err: error.message });
                }
            }

            setOpenBackdrop(false);

            if (errs.length !== 0) {
                setErrorMessage(
                    errs.map((e, index) => (
                        <span key={index}>
                            <b>{e.name}</b> {e.name !== "" ? ":" : ""} {e.err}
                            <br />
                        </span>
                    ))
                );
                setErrorOpen(true);
            }
        },
        [submission]
    );

    const { getRootProps, getInputProps, open, isDragActive, isDragAccept, isDragReject } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        onDrop,
        maxFiles: 10,
        maxSize: 15728640,
        accept: "application/pdf, image/jpg,image/jpeg,image/png,image/gif", //,
    });

    const cancelUpload = () => {
        CancelUpload(cancelTokenSource);
    };

    const progressCallback = (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
    };

    const clearErrorCallback = () => {
        setErrorOpen(false);

        if (errorMessage.includes("You cannot access a Completed submission.") || errorMessage.includes("The submission record does not exist.")) {
            history.push("/home");
        }
        if (errorMessage === "You need to log in before you can access this page") {
            history.push("/");
        }
    };

    const deleteCallback = async (doDelete) => {
        setOpenConfirmDelete(false);

        if (doDelete) {
            try {
                const res = await DeleteFile(submission, activeFileId);

                setSubmission(res);
            } catch (error) {
                setErrorMessage(error.name + ": " + error.message);
                setErrorOpen(true);
            }
        }
    };

    const deleteFileCallback = async (fileId) => {
        setActiveFileId(fileId);
        setOpenConfirmDelete(true);
    };

    let { id } = useParams();

    useEffect(() => {
        ReactGA.modalview("/uploadDocuments");

        async function fetchData() {
            try {
                const result = await GetSubmission(id);
                if (result.Status !== "Draft") {
                    setErrorMessage("You cannot access a Completed submission.");
                    setErrorOpen(true);
                } else {
                    setSubmission(result);
                }
            } catch (error) {
                setErrorMessage(error.message);
                setErrorOpen(true);
            }
        }
        fetchData();
    }, [id]);

    // const truncateFileName = (name) => {
    //     if (name.length > 40) return name.substring(0, 40) + "...";
    //     else return name;
    // };

    const decodeError = (code) => {
        switch (code) {
            case "file-too-large":
                return "The maximum file size you can upload is 15 megabytes.";
            case "too-many-files":
                return "No more than 10 files can be uploaded at a time.";
            case "file-invalid-type":
                return "Please upload files in PNG, JPEG, GIF or PDF format.";
            default:
                return code;
        }
    };

    const handleNext = () => {
        if (submission.UploadedFiles.length === 0) {
            setErrorMessage("At least one document must be uploaded to continue");
            setErrorOpen(true);
        } else {
            history.push("/submit/" + id);
        }
    };

    const style = useMemo(() => {
        const activeStyle = {
            borderColor: "#ffffff",
        };

        const acceptStyle = {
            borderColor: "#005a9c",
        };

        const rejectStyle = {
            borderColor: "#ff1744",
        };

        return {
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        };
    }, [isDragActive, isDragAccept, isDragReject]);

    return (
        <div style={{ height: "100%" }}>
            <Navigation stepIndex={1}>
                <Typography variant="h2">This is where you upload documents for your submission.</Typography>

                <Typography variant="body1" className={classes.controlContainer}>
                    When uploading documents please check that your scanned documents (PDF) and images (JPG, PNG, GIF) are clear and easy to read.
                </Typography>

                <Grid container className={classes.splitPage} spacing={2}>
                    <Grid item xs={12} sm={6} className={classes.dropAreaContainer}>
                        <div {...getRootProps({ style })} className={classes.dropArea}>
                            <input {...getInputProps()} />
                            <div>
                                <CloudUploadIcon color="secondary" fontSize="large" />
                                <Typography variant="h3">Drag and drop documents here</Typography>
                                <Typography variant="body1">OR</Typography>

                                <Button variant="contained" color="primary" onClick={open} disableRipple>
                                    Browse files
                                </Button>
                            </div>
                        </div>
                        {/* <div className={classes.onlyButton} hidden={!smallScreen}>
                            <Button variant="contained" color="primary" onClick={open}>
                                Browse files to upload
                            </Button>
                            
                        </div> */}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h3">Documents already uploaded</Typography>
                        <ul className={classes.fileContainer}>
                            {submission.UploadedFiles &&
                                submission.UploadedFiles.map((file, index) => (
                                    <li key={index}>
                                        <Typography variant="body1" className={classes.fileSection} noWrap>
                                            <Tooltip title="delete">
                                                <IconButton
                                                    aria-label="delete document"
                                                    color="primary"
                                                    onClick={() => deleteFileCallback(file.FileId)}
                                                    size="small"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {file.UploadedFileName}
                                        </Typography>
                                    </li>
                                ))}
                        </ul>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={4} sm={2}>
                        <Button
                            variant="contained" // disabled={activeStep === 0}
                            component={RouterLink}
                            to={"/studentDetails/" + id}
                            color="secondary"
                            fullWidth
                            disableRipple
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={4} sm={8}></Grid>
                    <Grid item xs={4} sm={2}>
                        <Button variant="contained" color="primary" onClick={handleNext} fullWidth disableRipple>
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Navigation>

            <Dialog open={openBackdrop} aria-labelledby="upload-dialog-title" aria-describedby="upload-dialog-description" className={classes.centerText}>
                <DialogTitle id="upload-dialog-title" className={classes.centerText}>
                    Uploading files
                </DialogTitle>
                <DialogContent id="upload-dialog-description">
                    <Box position="relative" color="inherit" display="flex" alignItems="center" justifyContent="center">
                        {!success ? (
                            <div>
                                <CircularProgress variant={progress < 100 ? "determinate" : "indeterminate"} value={progress} color="primary" size="60px" />
                                <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                                    <Typography variant="body2" component="div" color="inherit">
                                        {progress}%
                                    </Typography>
                                </Box>
                            </div>
                        ) : (
                            <Box top={0} left={0} bottom={0} right={0} display="flex" alignItems="center" justifyContent="center">
                                <CheckCircle style={{ fontSize: 60, color: "green" }} />
                            </Box>
                        )}
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%" aria-live="assertive">
                        <Typography variant="body1" color="inherit" noWrap className={classes.centerText}>
                            {success ? "Upload complete" : progress < 100 ? "Uploading " + progressName : "Validating " + progressName}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                        <Button variant="contained" color="primary" onClick={cancelUpload} disabled={progress === 100} disableRipple>
                            {/* disabled={progress === 100} */}
                            Cancel
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            {/* delete confirmation */}
            <ConfirmDialog
                open={openConfirmDelete}
                confirmAction="Delete"
                confirmMessage="Are you sure you want to delete this document?"
                confirmCallback={deleteCallback}
            />

            <ErrorDialog errorMessage={errorMessage} open={errorOpen} clearErrorCallback={clearErrorCallback} />
        </div>
    );
}

export default UploadDocuments;
