import React, { useState, useEffect, useRef } from "react";

import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
// import TickIcon from "@material-ui/icons/CheckBox";
// import CrossIcon from "@material-ui/icons/Cancel";
import Grid from "@material-ui/core/Grid";
//import OpenIcon from "@material-ui/icons/OpenInBrowser";
import Tooltip from "@material-ui/core/Tooltip";
import MainTheme from "../Shared/MainTheme";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { Link as RouterLink } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";

import ReactGA from "react-ga";

import ErrorDialog from "../ErrorDialog/ErrorDialog";
import HelpDialog from "../HelpDialog/HelpDialog";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import configuration from "../Shared/Config";
import Navigation from "../Navigation/Navigation";

import { GetSubmission, UpdateSubmission, DeleteFile } from "../Shared/ApiAccess";

const useStyles = makeStyles(() => ({
    controlContainer: {
        marginBottom: "0.5rem",
    },

    previewArea: {
        // border: "solid",
        // borderWidth: "1px 1px 1px 1px ",
        // borderColor: MainTheme.palette.grey[400],
        marginBottom: "0.5rem",
        listStyleType: "none",
        paddingLeft: "0px",
    },
    exampleArea: {
        border: "solid",
        borderWidth: "1px 1px 1px 1px ",
        borderColor: MainTheme.palette.grey[400],
        marginBottom: "1rem",
    },

    root: {},

    imageClass: {
        // maxWidth: "300px",
        // maxHeight: "300px",
        width: "100%",
        height: "auto",
        borderColor: MainTheme.palette.grey[400],
    },

    imageFrame: {
        // position: "relative",
        // width: "auto",
        // height: "auto",
        padding: "1px",
        margin: "6px",
        border: "solid",
        borderWidth: "1px",
        borderColor: MainTheme.palette.grey[400],
    },
    fileItemImage: {
        position: "relative",
        width: "auto",
        height: "auto",
        // padding: "1px",
        // margin: "6px",
        // border: "solid",
        // borderWidth: "1px",
        // borderColor: MainTheme.palette.grey[400],
    },

    fileItemActions: {
        // position: "absolute",
        // opacity: "0.6",
        // bottom: "0px",
        // left: "0px",
        // backgroundColor: "white",
        // width: "100%",
        // height: "50px",
    },
    fileName: {
        // position: "absolute",
        // opacity: "0.8",
        // top: "0px",
        // left: "0px",
        // backgroundColor: "white",
        // width: "100%",
        // height: "40px",
        paddingLeft: "12px",
    },
    imageTitle: {
        display: "flex",
        alignItems: "center",
    },

    title: {
        color: "black",
        // paddingLeft: "0.5rem",
        // paddingRight: "0.5rem",
        marginBottom: "0px",
    },
    actions: {
        color: MainTheme.palette.grey[600],
    },
    rightColumn: {
        alignItems: "right",
        display: "flex",
        justifyContent: "center",
    },

    error: {
        color: "#ca1734",
        marginLeft: "2.5rem",
    },

    errorStyle: {
        fontSize: "0.875rem",
    },
}));

function Submit() {
    const classes = useStyles();
    const history = useHistory();
    const filesCheckedRef = useRef(null);
    const permissionCheckedRef = useRef(null);

    const [helpOpen, setHelpOpen] = useState(false);
    const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [permissionChecked, setPermissionChecked] = useState(false);
    const [filesChecked, setFilesChecked] = useState(false);
    const [submission, setSubmission] = useState({});
    const [showCompleteMessage, setShowCompleteMessage] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [activeFileId, setActiveFileId] = useState();

    const submitApplication = async () => {
        submission.Status = "Completed";
        setSubmission(submission);

        try {
            let result = await UpdateSubmission(submission);
            setSubmission(result);
            setShowCompleteMessage(true);
            ReactGA.event({
                category: "Submit Button",
                action: "Submission Completed",
            });
        } catch (error) {
            setErrorMessage(error.message);
            setErrorOpen(true);
        }
    };

    let { id } = useParams();

    useEffect(() => {
        ReactGA.modalview("/submit");

        async function fetchData() {
            try {
                const result = await GetSubmission(id);

                if (result.Status !== "Draft") {
                    setErrorMessage("You cannot access a Completed submission.");
                    setErrorOpen(true);
                } else {
                    setSubmission(result);
                }
            } catch (err) {
                setErrorMessage(err.message);
                setErrorOpen(true);
            }
        }
        fetchData();
    }, [id]);

    const submitButtonClicked = () => {
        if (!permissionChecked) {
            setShowErrors(true);
            setShowValidation(true);
            permissionCheckedRef.current.focus();
        } else if (!filesChecked) {
            //} || submission.UploadedFiles.length === 0) {
            setShowErrors(true);
            setShowValidation(true);
            filesCheckedRef.current.focus();
        } else if (submission.UploadedFiles.length === 0) {
            setShowErrors(true);
            setShowValidation(true);
        } else {
            setConfirmSubmitOpen(true);
        }
    };

    const clearErrorCallback = () => {
        if (errorMessage === "You need to log in before you can access this page") {
            history.push("/");
        } else {
            history.push("/home");
        }
        setErrorOpen(false);
    };

    const deleteFileCallback = async (fileId) => {
        setActiveFileId(fileId);
        setOpenConfirmDelete(true);
    };

    const deleteCallback = async (doDelete) => {
        setOpenConfirmDelete(false);

        if (doDelete) {
            try {
                const res = await DeleteFile(submission, activeFileId);

                setSubmission(res);
            } catch (error) {
                setErrorMessage(error.name + ": " + error.message);
                setErrorOpen(true);
            }
        }
    };

    const clickSubmitConfirmed = (doProceed) => {
        setConfirmSubmitOpen(false);
        if (doProceed) {
            submitApplication();
            // props.navigateCallback(1);
        }
    };

    return (
        <div style={{ height: "100%" }}>
            <Navigation stepIndex={2}>
                <Typography variant="body1">
                    Please check the list of documents you have uploaded to ensure that you have supplied everything that StudyLink needs.
                </Typography>
                <Typography variant="body1">
                    Check that your files are clear and easy to read. If you’re returning a prefilled form make sure the full barcode with numbers can be read.
                    <Tooltip title="help">
                        <IconButton aria-label="help" color="primary" onClick={() => setHelpOpen(true)} size="small">
                            <HelpIcon />
                        </IconButton>
                    </Tooltip>
                </Typography>
                <div>
                    <div>
                        <Typography variant="body1">The following documents will be submitted to StudyLink:</Typography>
                        <Grid container className={classes.previewArea} component="ul">
                            {submission.UploadedFiles &&
                                submission.UploadedFiles.map((file, index) => (
                                    <Grid item xs={6} sm={4} md={3} key={index} component="li">
                                        <div className={classes.imageFrame}>
                                            <div className={classes.fileItemImage}>
                                                <IconButton
                                                    aria-label="open document in new window"
                                                    size="medium"
                                                    className={classes.actions}
                                                    component={Link}
                                                    target="_blank"
                                                    href={configuration.baseUrl + "/files/" + file.FileId}
                                                >
                                                    <img
                                                        src={configuration.baseUrl + "/thumbnails/" + file.FileId}
                                                        alt={file.UploadedFileName}
                                                        className={classes.imageClass}
                                                    />
                                                </IconButton>
                                            </div>
                                            <Grid container className={classes.fileName}>
                                                <Grid item xs={10} className={classes.imageTitle}>
                                                    <Typography variant="body1" className={classes.title} noWrap>
                                                        {file.UploadedFileName}
                                                    </Typography>
                                                </Grid>
                                                {/* </Grid> */}
                                                {/* <Grid container className={classes.fileItemActions}> */}
                                                <Grid item xs={2} className={classes.rightColumn}>
                                                    <Tooltip title="delete">
                                                        <IconButton
                                                            aria-label="delete document"
                                                            className={classes.actions}
                                                            onClick={() => deleteFileCallback(file.FileId)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                ))}
                        </Grid>
                    </div>
                </div>

                {showErrors && submission.UploadedFiles.length === 0 && (
                    <FormControl error={true}>
                        <FormHelperText className={classes.errorStyle}>
                            You cannot complete a submission without attaching a document. Please attach the document(s) you want to send to StudyLink.
                        </FormHelperText>
                    </FormControl>
                )}
                <FormGroup row>
                    <Grid item sm={12}>
                        <FormControl error={showErrors && !filesChecked}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filesChecked}
                                        ref={filesCheckedRef}
                                        onChange={(e) => {
                                            setFilesChecked(e.target.checked);
                                        }}
                                    />
                                }
                                label="I have checked the quality of the documents and images"
                            />
                            <FormHelperText className={classes.errorStyle}>
                                {showErrors && !filesChecked && "Please check the quality of the documents and images."}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                        <FormControl error={showErrors && !permissionChecked}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={permissionChecked}
                                        ref={permissionCheckedRef}
                                        onChange={(e) => {
                                            setPermissionChecked(e.target.checked);
                                        }}
                                        name="checkedA"
                                    />
                                }
                                label=" I give my permission for Connect to submit these documents to StudyLink"
                            />
                            <FormHelperText className={classes.errorStyle}>
                                {showErrors && !permissionChecked && "Please give permission for Connect to submit these documents to StudyLink."}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </FormGroup>

                <Typography variant="body1" className={classes.controlContainer}>
                    Please ensure that you have attached all required documents and supporting evidence, as any missing information may cause delays to your
                    application.
                </Typography>

                <Grid container>
                    <Grid item xs={4} sm={2}>
                        <Button variant="contained" component={RouterLink} to={"/upload/" + id} color="secondary" fullWidth disableRipple>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={4} sm={8}></Grid>
                    <Grid item xs={4} sm={2}>
                        <Button variant="contained" color="primary" onClick={submitButtonClicked} fullWidth disableRipple>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Navigation>

            {/* help dialog */}
            <HelpDialog showHelp={helpOpen} clearHelpCallback={() => setHelpOpen(false)}>
                <Typography variant="body1">Click on the thumbnail to view your uploaded document in a new window.</Typography>
                <Typography variant="body1">
                    If the file is not clear and easy to read, please replace it with a better quality version. If you’re sending a prefilled form make sure the
                    full barcode including numbers can be read. Bad quality images or images where we can’t see the barcode or the barcode numbers can cause
                    delays in your application.
                </Typography>
            </HelpDialog>

            {/* confirm submit */}
            <ConfirmDialog
                open={confirmSubmitOpen}
                confirmAction="Submit"
                confirmMessage="Are you sure you want to submit these documents to StudyLink?"
                confirmCallback={clickSubmitConfirmed}
            />

            {/* Complete message */}
            <Dialog open={showCompleteMessage} aria-labelledby="complete-dialog-title" aria-describedby="complete-dialog-description">
                <DialogTitle id="complete-dialog-title">Submission complete</DialogTitle>
                <DialogContent>
                    <DialogContentText id="complete-dialog-description">
                        <Typography variant="body1">Your documents have been submitted to StudyLink.</Typography>
                        <Typography variant="body1">
                            StudyLink will be in contact to advise the outcome of your application or if they require any further information. A confirmation
                            email has been sent to you from noreply@connect.co.nz
                        </Typography>
                        <Typography variant="body1">(If you don&apos;t receive it please check your spam folder.)</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="close" component={RouterLink} to="/home" color="primary" variant="contained" autoFocus disableRipple>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Validation */}
            <Dialog open={showValidation} aria-labelledby="validation-dialog-title" aria-describedby="validation-dialog-description">
                <DialogTitle id="validation-dialog-title">Errors</DialogTitle>
                <DialogContent>
                    <DialogContentText id="validation-dialog-description">
                        <Typography variant="body1">
                            Please fix the following error(s):
                            <ul>
                                {submission.UploadedFiles && submission.UploadedFiles.length === 0 && (
                                    <li>
                                        You cannot complete a submission without attaching a document. Attach the document(s) you want to send to StudyLink.
                                    </li>
                                )}
                                {!filesChecked && <li> Confirm you have checked the quality of the documents and images.</li>}
                                {!permissionChecked && <li>Give permission for Connect to submit these documents to StudyLink.</li>}
                            </ul>
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="validation-close" color="primary" variant="contained" autoFocus disableRipple onClick={() => setShowValidation(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* delete confirmation */}
            <ConfirmDialog
                open={openConfirmDelete}
                confirmAction="Delete"
                confirmMessage="Are you sure you want to delete this document?"
                confirmCallback={deleteCallback}
            />

            {/* error */}
            <ErrorDialog errorMessage={errorMessage} open={errorOpen} clearErrorCallback={clearErrorCallback} />
        </div>
    );
}

export default Submit;
