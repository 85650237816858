import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

function ConfirmDialog(props) {
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={() => props.confirmCallback(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionProps={{ role: "presentation" }}
            >
                <DialogTitle id="alert-dialog-title">{props.confirmAction}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{props.confirmMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        id="cancel"
                        onClick={() => {
                            props.confirmCallback(false);
                        }}
                        color="secondary"
                        variant="contained"
                        disableRipple
                    >
                        Cancel
                    </Button>
                    <Button
                        id="delete"
                        onClick={() => {
                            props.confirmCallback(true);
                        }}
                        color="primary"
                        variant="contained"
                        autoFocus
                        disableRipple
                    >
                        {props.confirmAction}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ConfirmDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    confirmAction: PropTypes.string.isRequired,
    confirmMessage: PropTypes.string.isRequired,
    confirmCallback: PropTypes.func.isRequired,
};

export default ConfirmDialog;
