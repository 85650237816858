import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";

import { Link as RouterLink } from "react-router-dom";

import msdLogo from "./msd-logo.svg";
import nzGovtLogo from "./nz-govt-logo.svg";
import footerBackgroud from "./footer-bg.png";

const useStyles = makeStyles(() => ({
    footer1Container: {
        display: "flex",
        backgroundImage: "url(" + footerBackgroud + ")",
        backgroundRepeat: "repeat",
        minHeight: "90px",
        alignItems: "center",
        padding: "15px",
    },
    footer2Container: {
        height: "50px",
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        backgroundColor: "#152B44",
        padding: "15px",
    },
    tandc: {
        color: "white",
        fontFamily: "Fira Sans",
        fontSize: "14px",
    },
    govtLogoSection: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: "10px",
        marginBottom: "10px",
    },
    termsSection: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: "10px",
        marginBottom: "10px",
    },
    contactUsSection: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: "10px",
        marginBottom: "10px",
    },
    appspacer: {
        width: "30px",
    },
}));

function Footer() {
    const classes = useStyles();

    return (
        <footer>
            <div className={classes.footer1Container}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Link href="https://www.msd.govt.nz/">
                            <img src={msdLogo} alt="Go to Ministry of Social Development" height="50" width="208" />
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.govtLogoSection}>
                        <Link href="https://www.govt.nz/">
                            <img
                                height="21"
                                width="199"
                                src={nzGovtLogo}
                                alt="govt.nz - connecting you to New Zealand central &amp; local government services"
                            />
                        </Link>
                    </Grid>
                </Grid>
            </div>

            <div className={classes.footer2Container}>
                <Grid container>
                    <Grid item xs={6} className={classes.termsSection}>
                        <Link component={RouterLink} className={classes.tandc} to="/userTerms" underline="always">
                            Connect Terms and Conditions
                        </Link>
                    </Grid>
                    <Grid item xs={6} className={classes.contactUsSection}>
                        <Link className={classes.tandc} component={RouterLink} to="/help" underline="always">
                            Help
                        </Link>
                        <span className={classes.appspacer} />
                        <Link className={classes.tandc} href="https://studylink.govt.nz/about-studylink/contact-us/index.html" underline="always">
                            Contact Us
                        </Link>
                    </Grid>
                </Grid>
            </div>
        </footer>
    );
}

export default Footer;
