import axios from "axios";
import configuration from "./Config";

if (configuration.debug) {
    axios.defaults.baseURL = configuration.baseUrl;
} else {
    axios.defaults.headers.common["Cache-Control"] = "no-cache";
    axios.defaults.headers.common["Pragma"] = "no-cache";
    axios.defaults.headers.common["Expires"] = "0";

    axios.interceptors.response.use(function (response) {
        if (response.headers["x-csrf-token"]) axios.defaults.headers.common["X-CSRF-Token"] = response.headers["x-csrf-token"];

        return response;
    });
}

axios.defaults.timeout = configuration.timeout;

const transformOneSubmissionOldFormat = (subIn) => {
    let subOut = {
        ...subIn,
        ApplicationTypeDescription: "Student Loan",
        ApplicationTypeDescriptionId: 10,
        FormTypeDescription: "Student Loan Application Form",
        FormTypeDescriptionId: 10,
        Evidence: [],
        OtherEvidence: [],
    };

    subIn.OtherEvidence.forEach((e) => (subOut.UploadedFiles = [...subOut.UploadedFiles, ...e.UploadedFiles]));

    subIn.Evidence.forEach((e) => (subOut.UploadedFiles = [...subOut.UploadedFiles, ...e.UploadedFiles]));

    return subOut;
};

const transformAllSubmissionsOldFormat = (subsIn) => {
    return subsIn.map((e) => transformOneSubmissionOldFormat(e));
};

export async function Ping() {
    await axios.get("/ping");
}

export async function PingWithExceptions() {
    const result = await axios.get("/ping");
    if (result.data.status !== "success") throw new Error(result.data.Description);
}

export async function GetAllSubmissions() {
    const result = await axios.get("/submissions");
    if (result.data.status !== "success") throw new Error(result.data.Description);
    // filter out corrputed submissions
    const filteredSub = result.data.context.filter((s) => s.Status);
    return transformAllSubmissionsOldFormat(filteredSub);
}

export async function GetSubmission(id) {
    const result = await axios.get("/submissions/" + id);
    if (result.data.status !== "success") throw new Error(result.data.Description);
    return transformOneSubmissionOldFormat(result.data.context);
}

async function DeleteAllSubmissionFiles(files) {
    let apiFiles = files.map((file) => {
        let a = { fileId: file.FileId };
        return a;
    });

    await axios.delete("/files", { data: apiFiles });

    return null;
}

export async function DeleteSubmission(submission) {
    let result = await axios.delete("/submissions/" + submission.SubmissionId);

    if (result.data.status !== "success") {
        throw new Error(result.Description);
    } else {
        if (submission.UploadedFiles.length !== 0) {
            await DeleteAllSubmissionFiles(submission.UploadedFiles);
        }
    }

    return null;
}

export async function CreateSubmission(submission) {
    const result = await axios.post("/submissions", submission);

    if (result.data.status !== "success") throw new Error(result.data.Description);
    submission.SubmissionId = result.data.context.submissionId;
    submission.UserId = result.data.context.userId;
    submission.DateCreated = result.data.context.dateCreated;
    submission.TimeCreated = result.data.context.timeCreated;
    submission.DateModified = result.data.context.dateModified;
    submission.TimeModified = result.data.context.timeModified;

    return submission;
}

export async function UpdateSubmission(submission) {
    const result = await axios.put("/submissions/" + submission.SubmissionId, submission);

    if (result.data.status !== "success") throw new Error(result.data.Description);

    submission.DateModified = result.data.context.DateModified;
    submission.TimeModified = result.data.context.TimeModified;

    return submission;
}

export function GetCancelTokenSource() {
    const CancelToken = axios.CancelToken;
    return CancelToken.source();
}
export function CancelUpload(source) {
    source.cancel("Document upload cancelled.");
}

export async function CreateFile(submission, fileIn, progressCallback, cancelTokenSource) {
    const formData = new FormData();
    formData.append("FileUpload", fileIn);
    formData.append("clientNumber", submission.ClientNumber);

    const config = {
        headers: {
            "Content-Type": `multipart/form-data`,
        },
        onUploadProgress: progressCallback,
        cancelToken: cancelTokenSource.token,
        timeout: configuration.fileTimeout,
    };

    const result = await axios.post("/files", formData, config);

    if (result.data.status !== "success") {
        var err = "The document attached cannot be processed. Please recreate the document and attach to this submission.";
        if (result.data.Description.includes("This file requires a password for access.")) {
            err = "The PDF that you have uploaded is password protected and it has been rejected. Please only upload PDFs that are not password protected.";
        }
        if (result.data.context == "Duplicate") {
            err =
                "It looks like you’ve added this document in the last 6 months. You don’t need to add this again. If you haven’t already added it to your current submission, please check your drafts to make sure it’s been sent to StudyLink.";
        }
        if (result.data.context == "MaxFileUpload") {
            err = "Sorry you cannot upload more than 30 files in a day.";
        }

        throw new Error(err);
    }

    const file = {
        UploadedFileName: result.data.context.UploadedFileName,
        FileId: result.data.context.FileId,
        StorageUploadedFileName: result.data.context.StorageUploadedFileName,
        StorageThumbnailFileName: result.data.context.StorageThumbnailFileName,
        DuplicateNotificationFlag: "N",
    };

    submission.UploadedFiles.push(file);

    await UpdateSubmission(submission);

    return submission;
}

export async function DeleteFile(submission, fileId) {
    //remove file from object
    const index = submission.UploadedFiles.findIndex((element) => element.FileId === fileId);
    submission.UploadedFiles.splice(index, 1);
    await UpdateSubmission(submission);

    await axios.delete("/files/" + fileId);

    return submission;
}
