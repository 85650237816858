import React from "react";
import { useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Stepper } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Container from "@material-ui/core/Container";

import { useParams, useHistory } from "react-router-dom";

import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowX: "hidden",
        minWidth: "305px",
    },
    appbar: {
        flexGrow: 1,
    },
    buttonContainer: {
        display: "flex",
        flexGrow: 1,
    },
    buttonSpacer: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    appspacer: {
        flexGrow: 1,
    },
    mainBody: {
        paddingTop: "1.5rem",
        paddingBottom: "1.5rem",
        flexGrow: "1",
    },
    NavigationBody: {
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: ".75rem",
        paddingBottom: ".75rem",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#ffffff",
        flexDirection: "column",
        display: "flex",
    },
}));

function getSteps() {
    return ["Provide student details", "Upload documents", "Review and submit"];
}

const Navigation = (props) => {
    const classes = useStyles();
    const steps = getSteps();
    const history = useHistory();
    const headingRef = useRef(null);

    let { id } = useParams();

    const handleStep = (index) => {
        switch (index) {
            case 0:
                history.push("/studentDetails/" + id);
                break;
            case 1:
                history.push("/upload/" + id);
                break;
            default:
                throw new Error("invalid step index: " + index);
        }
    };

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    useEffect(() => {
        const clearTabIndex = async (control) => {
            await sleep(500);

            control.removeAttribute("tabindex");
        };
        headingRef.current.focus();
        clearTabIndex(headingRef.current);
    }, []);

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Helmet>
                <title>{"Connect with StudyLink - " + steps[props.stepIndex] + " (Step " + (props.stepIndex + 1) + " of 3)"}</title>
            </Helmet>

            <Header />
            <main id="main-content" className={classes.mainBody}>
                <Typography variant="h1" ref={headingRef} tabIndex="-1">
                    {steps[props.stepIndex]} (Step {props.stepIndex + 1} of 3)
                </Typography>
                {/* stepper */}

                <nav>
                    <Stepper activeStep={props.stepIndex}>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label} aria-current={index === props.stepIndex ? "step" : null}>
                                    <StepButton onClick={() => handleStep(index)} disabled={index >= props.stepIndex} completed={index < props.stepIndex}>
                                        {label}
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </nav>
                <div className={classes.NavigationBody}>{props.children}</div>
            </main>

            <Footer />
        </Container>
    );
};

Navigation.propTypes = {
    stepIndex: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
};

export default Navigation;
