const config = {
    debug: false,
    timeout: 40000, // API timeout
    fileTimeout: 1200000,
    idleTimeout: 14 * 60 * 1000 - 5000,
    continueTimeout: 15 * 60 * 1000 - 5000, // note this is total time not additional time. Server timeout is 15 mins
    idleTick: 1000,
};

if (window.location.hostname === "localhost") {
    config.debug = true;
}

if (config.debug) config.baseUrl = "http://www.connect-dev.co.nz/";
//"http://connectdev.h383bhkv3h.ap-southeast-2.elasticbeanstalk.com";
else config.baseUrl = "";

export default config;
