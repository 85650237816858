import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

import PropTypes from "prop-types";

function HelpDialog(props) {
    return (
        <div>
            <Dialog
                open={props.showHelp}
                onClose={() => props.clearHelpCallback()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionProps={{ role: "presentation" }}
            >
                <DialogTitle id="alert-dialog-title">Help</DialogTitle>
                <DialogContent id="alert-dialog-description">{props.children}</DialogContent>
                <DialogActions>
                    <Button id="close" onClick={() => props.clearHelpCallback()} color="primary" variant="contained" autoFocus disableRipple>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

HelpDialog.propTypes = {
    showHelp: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    clearHelpCallback: PropTypes.func.isRequired,
};

export default HelpDialog;
