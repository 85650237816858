import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

function ErrorDialog(props) {
    return (
        <div>
            {/* Error message */}
            <Dialog
                open={props.open}
                onClose={() => props.clearErrorCallback()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionProps={{ role: "presentation" }}
            >
                <DialogTitle id="alert-dialog-title">Error</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{props.errorMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="close" onClick={() => props.clearErrorCallback()} color="primary" variant="contained" autoFocus disableRipple>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ErrorDialog.propTypes = {
    errorMessage: PropTypes.any,
    open: PropTypes.bool,
    clearErrorCallback: PropTypes.func.isRequired,
};

export default ErrorDialog;
