import React, { useState, useRef, useEffect } from "react";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import Tooltip from "@material-ui/core/Tooltip";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minWidth: "305px",
    },
    mainBody: {
        paddingTop: "24px",
        paddingBottom: "24px",
        flexGrow: "1",
    },
    heading: {
        color: "#005a9c",
        fontWeight: "normal",
        fontSize: "16px",
        marginBottom: "0px",
    },
    content: {
        paddingLeft: "20px",
    },
}));

function Help() {
    const classes = useStyles();
    const history = useHistory();
    const [expanded, setExpanded] = useState(false);
    const headingRef = useRef(null);

    useEffect(() => {
        function sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }
        const clearTabIndex = async (control) => {
            await sleep(500);

            control.removeAttribute("tabindex");
        };
        headingRef.current.focus();
        clearTabIndex(headingRef.current);
    }, []);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Helmet>
                <title>Connect with StudyLink - Help</title>
            </Helmet>

            <Header />

            <main id="main-content" className={classes.mainBody}>
                <Typography variant="h1" ref={headingRef} tabIndex="-1">
                    Help
                </Typography>
                <Accordion expanded={expanded === "r1"} onChange={handleChange("r1")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "r1" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            What is RealMe?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography>
                                <b>RealMe</b> is a service from the New Zealand Government and New Zealand Post that includes a single login, letting you use
                                one username and password to access a wide range of services online
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "r2"} onChange={handleChange("r2")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "r2" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            How do I create a Connect account?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography>
                                You can create a Connect account and digitally submit your StudyLink applications and documents by simply logging in to the
                                service using your RealMe username and password.
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "r3"} onChange={handleChange("r3")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "r3" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            What can I do if I don’t have a RealMe login?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography>
                                {" "}
                                Simply click on the “Create your RealMe login now” link on the Connect login page and complete the necessary steps. When you are
                                finished you will be returned to the Connect site to login.
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "r4"} onChange={handleChange("r4")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "r4" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            What if I can’t remember my RealMe username or password?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography>
                                You may reset your username or password by clicking the ‘Forgot Username” or &apos;Forgot password?&apos; link on the login page
                                of Connect.
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "r5"} onChange={handleChange("r5")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "r5" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography className={classes.heading} variant="h2">
              How do I change my RealMe password?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography>     To change any of your login details, click on the Manage my RealMe link once you have logged in to Connect then follow the steps. This can
                    be found on the bottom right hand corner.</Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === "1"} onChange={handleChange("1")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "1" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            Why do I get a Pop Up message telling me &#8220;It looks like you’ve added this document in the last 6 months. You don’t need to
                            send this again.&#8221;?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1" component={"span"}>
                                You will receive this message if:
                                <ul>
                                    <li>You have already attached the same document to your current submission</li>
                                    <li>If you have already sent this same document to StudyLink in the last 180 days</li>
                                    <li>
                                        If this document is already attached to a submission which you have started but not sent to StudyLink yet (This will
                                        show on your Home page as a submission in DRAFT).
                                    </li>
                                </ul>
                            </Typography>

                            <Typography>
                                It is very unusual for StudyLink to need the exact same document or piece of evidence from you more than once. Connect will stop
                                you sending information to StudyLink when you have already sent it to them using Connect.
                            </Typography>
                            <Typography>
                                If you do need to send the exact same document to StudyLink, you will need to take a new photo or scan of the
                                document/information and give it a different name before uploading it.
                            </Typography>
                            <Typography>If you want to send the document which is attached to a submission in DRAFT, EDIT the submission.</Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "2"} onChange={handleChange("2")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "2" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            How do I submit my documents to StudyLink?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1" component={"span"}>
                                There are three easy steps to send your documents using Connect:
                                <ol>
                                    <li>Sign in using RealMe.</li>
                                    <li>Add your email address, name and client number (or the student’s name and client number if you’re not the student).</li>
                                    <li>Upload your documents and submit.</li>
                                </ol>
                            </Typography>
                            <Typography>
                                You can upload all of your documents that StudyLink have asked for in one submission – including forms, evidence and documents.
                            </Typography>
                            <Typography>
                                StudyLink only need to see evidence once. You don’t need to submit any documents you have already submitted to StudyLink unless
                                you have been advised your evidence was not acceptable or you have had a change in circumstances.
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "3"} onChange={handleChange("3")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "3" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            What is my client number?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1">
                                Your client number is your StudyLink (Ministry of Social Development) customer number. This number is the same as your Work and
                                Income client number.
                            </Typography>
                            <Typography variant="body1">
                                This has been created by the Ministry of Social Development and should appear on all of your communications (including letters
                                and pre-filled application forms). Check the top right hand corner of your StudyLink letter. You can also find your client
                                number on your Community Services Card if you have one and at the top right of your MyStudyLink account
                            </Typography>
                            <Typography variant="body1">You will not be able to use Connect without entering your client number.</Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "4"} onChange={handleChange("4")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "4" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel4-content"
                        id="panel4-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            Why won&apos;t Connect accept my client number?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1">
                                Connect will only accept client numbers which are 9-digits long and do not contain spaces, letters or symbols (@#$%).
                            </Typography>
                            <Typography variant="body1">
                                Valid client numbers which are less than 9-digits long require zeros at the front in order to make the client number 9-digits
                                long.
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "5"} onChange={handleChange("5")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "5" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel5-content"
                        id="panel5-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            Do my documents for StudyLink need to be verified?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1">You can take a photo or scan an original document and upload it.</Typography>
                            <Typography variant="body1">
                                If you are taking a photo or scan of a copy of a document, this should be a verified copy. For more details on verified copies,
                                and who can verify documents, please go to{" "}
                                <a href="http://www.studylink.govt.nz" target="_blank" rel="noreferrer">
                                    www.studylink.govt.nz
                                </a>
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "8"} onChange={handleChange("8")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "8" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel8-content"
                        id="panel8-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            Can I upload any type of file?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1">
                                Connect will only allow you to upload the following file types - PDF and image (JPG, PNG, GIF) formats.
                            </Typography>
                            <Typography variant="body1">Password protected files cannot be uploaded.</Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "9"} onChange={handleChange("9")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "9" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel9-content"
                        id="panel9-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            What is the maximum sized file that can be uploaded?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1">
                                You can upload a document or image up to 15MB in size, however we strongly recommend not uploading files larger than 5MB to an
                                application. Large files can cause time-outs and upload errors.
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "10"} onChange={handleChange("10")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "10" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel10-content"
                        id="panel10-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            Why can’t I upload my files?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1">
                                You may be having difficulty because of the size of the file or your internet connectivity. Check that the files are less than
                                15MBs in size and that you have a strong internet connection (particularly if you are loading these from a mobile device).
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "11"} onChange={handleChange("11")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "11" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel11-content"
                        id="panel11-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            Does Connect store my documents and keep a history of my submissions?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1">
                                Draft submissions including uploaded files will only be stored for 48 hours before they are removed from Connect.
                            </Typography>
                            <Typography variant="body1">
                                After you have submitted your documents, Connect will display a list of your submission history.
                            </Typography>
                            <Typography variant="body1">
                                You will not be able to update or modify these submissions as the documents are not stored within Connect.
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "12"} onChange={handleChange("12")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "12" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel12-content"
                        id="panel12-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            How will I know if my documents have been submitted to StudyLink?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1">
                                You will receive a confirmation e-mail from Connect when your submission has been submitted successfully.
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "13"} onChange={handleChange("13")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "13" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel13-content"
                        id="panel13-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            How will I know that StudyLink has received my documents?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1">
                                Once you have submitted your documents through Connect, it can take up to 48 hours for StudyLink to receive them.
                            </Typography>
                            <Typography variant="body1">
                                You can check the &#8220;Documents Received&#8221; section of MyStudyLink to see that StudyLink has received your documents.
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "14"} onChange={handleChange("14")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "14" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel14-content"
                        id="panel14-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            How long will it take to process my documents?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1">
                                Once received by StudyLink, your document will usually be processed within 5 working days – but this will be longer during
                                busier times.
                            </Typography>
                            <Typography variant="body1">
                                You can check the progress of your application by logging into MyStudyLink at{" "}
                                <a href="http://www.studylink.govt.nz" target="_blank" rel="noreferrer">
                                    www.studylink.govt.nz
                                </a>
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "15"} onChange={handleChange("15")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "15" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel15-content"
                        id="panel15-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            Can my Parents and/or Partner submit documents to my StudyLink file using their own Connect account?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1">
                                Yes, Parents and Partners are able to submit documents to your StudyLink file using their own Connect account. They will need
                                your full name and client number so we can make sure any documents sent are correctly linked to you.
                            </Typography>
                            <Typography variant="body1">They will need to login to Connect by creating or using their own RealMe login.</Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "16"} onChange={handleChange("16")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "16" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel16-content"
                        id="panel16-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            Will I be logged out after a period of inactivity?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1">
                                Yes, to protect the security of your account, after an inactive period of 15 minutes you will be logged out of Connect and you
                                will need to enter your RealMe login details again to continue.
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "17"} onChange={handleChange("17")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "17" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel17-content"
                        id="panel17-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            Which web browsers support Connect?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1" component={"span"}>
                                The following browsers have been tested and are supported:
                                <ul>
                                    <li>Edge (latest version)</li>
                                    <li>Google Chrome (latest version)</li>
                                    <li>Mozilla Firefox (latest version)</li>
                                    <li>Apple Safari (latest version)</li>
                                </ul>
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "18"} onChange={handleChange("18")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "18" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel18-content"
                        id="panel18-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            I want to delete or close my Connect account – How do I do this?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1">
                                We&apos;re sorry that you want to leave Connect. To close an account, please contact us on{" "}
                                <a href="mailto:support@digitaltransformation.co.nz">support@digitaltransformation.co.nz</a>
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "19"} onChange={handleChange("19")}>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip title={expanded === "19" ? "close" : "expand"}>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        aria-controls="panel19-content"
                        id="panel19-header"
                    >
                        <Typography className={classes.heading} variant="h2">
                            Need help?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.content}>
                            <Typography variant="body1">If you are experiencing problems with the Connect service please contact StudyLink.</Typography>
                            <Typography variant="body1">
                                You can find StudyLink’s contact details on the contact us page at{" "}
                                <a href="http://www.studylink.govt.nz" target="_blank" rel="noreferrer">
                                    www.studylink.govt.nz
                                </a>
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Grid container>
                    <Grid item xs={4} md={5} />
                    <Grid item xs={4} md={2}>
                        <Button variant="contained" color="secondary" onClick={() => history.goBack()} fullWidth>
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </main>
            <Footer />
        </Container>
    );
}

export default Help;
