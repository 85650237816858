import React, { useRef, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minWidth: "305px",
    },
    mainBody: {
        paddingTop: "24px",
        paddingBottom: "24px",
        flexGrow: "1",
    },
}));

function TermsAndConditions() {
    const classes = useStyles();
    const history = useHistory();
    const headingRef = useRef(null);

    useEffect(() => {
        function sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }
        const clearTabIndex = async (control) => {
            await sleep(500);

            control.removeAttribute("tabindex");
        };
        headingRef.current.focus();
        clearTabIndex(headingRef.current);
    }, []);

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Helmet>
                <title>Connect with StudyLink - Terms and conditions</title>
            </Helmet>

            <Header />
            <main id="main-content" className={classes.mainBody}>
                <div>
                    <Typography variant="h1" ref={headingRef} tabIndex="-1">
                        Connect user terms
                    </Typography>
                </div>
                <Typography variant="h2">A. How do these terms of use work?</Typography>
                <Typography variant="body1">The Connect service is operated and owned by Techspace Connect Limited.</Typography>
                <Typography variant="body1">
                    Connect allows you to upload and digitally submit important information and documents to the Ministry of Social Development (MSD).
                </Typography>
                <Typography variant="body1">
                    These terms of use apply to your access to and use of Connect and information uploaded and submitted through Connect.
                </Typography>
                <Typography variant="body1">
                    We use the phrase “these terms” to cover all of these terms and conditions and any changes to them. Please read these terms carefully before
                    you start using Connect. By accessing and using Connect you agree to be bound by these terms. If you don’t accept them, you should not
                    access and use Connect.
                </Typography>
                <Typography variant="body1">
                    We may change the functionality of Connect, including these terms, at any time by posting changes online. Your continuing use of Connect
                    will mean that you agree to those changes.
                </Typography>
                <Typography variant="body1">
                    <b>In these terms:</b>
                </Typography>
                <Typography variant="body1">
                    <b>“you”</b> means the individual accessing and using Connect and “your” has a corresponding meaning. <br />
                    <b>“Connect”</b> means our digital submission service, and includes all underlying software and systems comprising our Connect service.{" "}
                    <br />
                    <b>“RealMe”</b> means the login service with which you access Connect. <br />
                    <b>“Connect website”</b> means our website www.connect.co.nz. <br />
                    <b>“We”</b> means Techspace Connect Limited. “Our” and “us” have a corresponding meaning.
                </Typography>
                <Typography variant="h2">B. Uploading and submitting important information and documents in Connect</Typography>
                <Typography variant="body1">To access the Connect service you require a RealMe login - www.realme.govt.nz</Typography>
                <Typography variant="body1">
                    Connect enables you to upload and digitally submit important information and documents for the purpose of making copies available to MSD
                    with your consent;
                </Typography>
                <Typography variant="body1" component={"span"}>
                    You agree that when you use Connect to upload and submit important information and documents (or any other content):
                    <ul>
                        <li>you own or you have the rights to upload and submit the information, documents or content using Connect;</li>
                        <li>
                            all information, documents and content that you upload and submit via Connect will not infringe anyone else’s intellectual property
                            or privacy rights and are not defamatory, discriminatory, offensive, obscene or otherwise illegal and that you understand you can
                            subject to legal action or be prosecuted if they do or are; and
                        </li>
                        <li>you will use the upload and submit functions of Connect solely for your personal use.</li>
                    </ul>
                </Typography>
                <Typography variant="h2">C. General terms</Typography>
                <Typography variant="h3">Your use of Connect</Typography>
                <Typography variant="body1">
                    The functionality of Connect is provided for your convenience only and you use Connect at your own risk.
                </Typography>
                <Typography variant="body1">Draft submissions will only be stored for 48 hours before they are removed from the system.</Typography>
                <Typography variant="body1">
                    You will be able to access the content of your submission history for 14 days after which the content will be removed; you will not be able
                    to update or modify submissions within the 14 day timeframe.
                </Typography>
                <Typography variant="body1">Once the 14 days have passed, Connect will simply display a list of your submission history.</Typography>
                <Typography variant="h3">Your obligations</Typography>
                <Typography variant="body1" component={"span"}>
                    You agree that when you access and use Connect you will:
                    <ul>
                        <li>comply with these terms;</li>
                        <li>comply with all applicable laws and regulations;</li>
                        <li>
                            comply with our requirements and instructions around correct use of Connect, as set out in the Connect website or otherwise notified
                            to you by us from time to time;
                        </li>
                        <li>not use Connect in a manner that infringes the rights of anyone else, or that restricts or inhibits their use of Connect;</li>
                        <li>not use Connect for any fraudulent or unlawful purpose;</li>
                        <li>not use Connect in a manner that brings the reputation and brand of Techspace Connect Limited into disrepute;</li>
                        <li>
                            not damage, interfere or harm Connect, or any website, network or system underlying or connected to Connect, or attempt to do so;
                        </li>
                        <li>not introduce any content or code to Connect or the Connect website which is technologically harmful;</li>
                        <li>
                            not use a robot, spider, scraper or other unauthorised automated means in relation to Connect or any information featured on Connect
                            for any purpose;
                        </li>
                        <li>not use Connect to transmit any communication (other than those communications permitted by Connect);</li>
                    </ul>
                </Typography>
                <Typography variant="h3">Termination of an account</Typography>
                <Typography variant="body1" component={"span"}>
                    You reserve the right to terminate your Connect account by giving us 30 days’ notice in writing, emailed to{" "}
                    <Link href="mailto:support@digitaltransformation.co.nz">support@digitaltransformation.co.nz</Link>
                    . <br />
                    We reserve the right to terminate your Connect account immediately on written notice to you, or at a date specified in that written notice,
                    if:
                    <ul>
                        <li>we believe you have breached any of these terms;</li>
                        <li>we no longer intend to provide our Connect service.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" component={"span"}>
                    Where we or you have terminated your Connect account, from the effective date of termination:
                    <ul>
                        <li>you will no longer have access to your Connect account or to any of the contents of that account.</li>
                    </ul>
                </Typography>
                <Typography variant="h3">Intellectual property rights</Typography>
                <Typography variant="body1" component={"span"}>
                    We own all copyright and other intellectual property rights in:
                    <ul>
                        <li>
                            Connect and the Connect website and all content therein, other than the content that you have uploaded and submitted via Connect;
                            and
                        </li>
                        <li>all data generated through Connect including through your use of Connect.</li>
                    </ul>
                </Typography>
                <Typography variant="body1">
                    You agree that you will not do anything that is inconsistent with or adversely affects our intellectual property rights.
                </Typography>
                <Typography variant="h3">Your privacy</Typography>
                <Typography variant="body1">
                    We will collect personal information about you as it relates to your use of the system and the specific submission you are completing and as
                    you use the Connect service.
                </Typography>
                <Typography variant="body1" component={"span"}>
                    In each case, we will only use this personal information for the purpose (or purposes) for which it was collected. This includes:
                    <ul>
                        <li>submitting your important information, documents and other content to MSD</li>
                        <li>the administration of Connect and providing our Connect service to you; and</li>
                        <li>improving our Connect service and user experience, including through undertaking surveys in that regard.</li>
                    </ul>
                </Typography>
                <Typography variant="body1">
                    We may also collect personal information for a purpose required or permitted by the Privacy Act 2020 or otherwise required or permitted by
                    law.
                </Typography>
                <Typography variant="body1">
                    We will keep any personal information you provide secure and will not disclose it to any other parties without your prior consent except as
                    otherwise provided in this agreement.
                </Typography>
                <Typography variant="body1">
                    We will also disclose personal information as required or permitted by the Privacy Act 2020 or otherwise required or permitted by law
                    including the provision of aggregated metadata to support requests made under the Official Information Act 1982. If we consider that you
                    have breached these terms or any applicable law we may provide your personal information as we see fit to enable us or any third party to
                    take court proceedings, enforce or apply our terms, or to protect any party’s rights, property or safety.
                </Typography>
                <Typography variant="body1">
                    We may use and share with providers or other third parties aggregated, anonymous information regarding Connect usage and trends for data
                    analytics purposes. This information will not be able to be linked back to you. <br />
                    If you want to check or correct information that we hold about you, please email{" "}
                    <Link href="mailto:privacy.officer@digitaltransformation.co.nz">privacy.officer@digitaltransformation.co.nz</Link>.
                </Typography>
                <Typography variant="h3">Security</Typography>
                <Typography variant="body1">
                    Information transmitted over the internet is inherently insecure. However, we have physical, electronic and managerial processes in place to
                    protect the information we collect through Connect. Items are passed through a secure server utilising encryption technology. All
                    information in your Connect account is encrypted and protected through secure passwords, user log-ons and other security procedures.
                </Typography>
                <Typography variant="h3">Our liability</Typography>
                <Typography variant="body1" component={"span"}>
                    You acknowledge and agree that we are not responsible or liable to you or any third party for:
                    <ul>
                        <li>any failure to provide access to Connect;</li>
                        <li>any services that MSD provides;</li>
                        <li>any content which you upload to Connect.</li>
                    </ul>{" "}
                </Typography>
                <Typography variant="body1">
                    We are not the agent of MSD which accepts digital submissions from Connect. We have no responsibility for any dealing or transaction between
                    you and MSD, or for the provision of products or services by MSD. You must deal with MSD directly to resolve any issues in relation to MSD’s
                    products or services.
                </Typography>
                <Typography variant="body1">
                    We will not be liable for any loss or damage (direct or otherwise) you suffer in connection with your use of the Connect service. We exclude
                    any condition or warranty that could be implied into these terms (but only to the extent that this is allowed by law).
                </Typography>
                <Typography variant="h3">Your liability</Typography>
                <Typography variant="body1">
                    You agree to indemnify us from and against all costs, claims damages or losses of any kind arising from any breach by you of these terms.
                </Typography>
                <Typography variant="h3">Governing law</Typography>
                <Typography variant="body1">
                    Your use of Connect is governed by the laws of New Zealand. When you access and use Connect, you submit to the non-exclusive jurisdiction of
                    the New Zealand courts.
                </Typography>
                <br />
                <Typography variant="body1">
                    <b>
                        Updated: 6<sup>th</sup> April 2021
                    </b>
                </Typography>
                <Grid container>
                    <Grid item xs={4} md={5} />
                    <Grid item xs={4} md={2}>
                        <Button variant="contained" color="secondary" onClick={() => history.goBack()} fullWidth>
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </main>
            <Footer />
        </Container>
    );
}

export default TermsAndConditions;
