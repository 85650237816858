import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

import { Link as RouterLink } from "react-router-dom";

import connectImage from "./connectlogo-noborder.png";
import studylinkImage from "./studylink-logo.svg";

const useStyles = makeStyles(() => ({
    logo: {
        margin: "0px",
    },
    titleImages: {
        display: "flex",
        alignItems: "center",
        marginTop: "8px",
    },
    studylinkLogoLink: {
        "&:focus": {
            outline: "2px solid black",
            outlineOffset: "2px",
        },
    },
    divider: {
        width: "2px",
        height: "33px",
        backgroundColor: "#ececec",
        margin: "2px 7px 2px 7px",
    },
    line1: {
        height: "2px",
        backgroundColor: "#ccdf87",
    },
    appbar: {
        flexGrow: 1,
        backgroundColor: "#005a9c",

        color: "white",
    },
    appspacer: {
        flexGrow: 1,
    },
}));

function Header() {
    const classes = useStyles();

    return (
        <div>
            <header>
                <div className={classes.titleImages}>
                    <img id="connectLogoButton" className={classes.logo} src={connectImage} alt="Connect logo" width="113" height="23" />
                    <div className={classes.divider}></div>
                    <Link href="https://www.studylink.govt.nz/" className={classes.studylinkLogoLink}>
                        <img id="studylinkLogoButton" className={classes.logo} src={studylinkImage} alt="StudyLink logo" width="172" height="33" />
                    </Link>
                </div>

                <div className={classes.line1} />
            </header>

            {/* App Bar */}
            <nav>
                <Paper position="static" className={classes.appbar}>
                    <Toolbar>
                        <Button color="inherit" component={RouterLink} to="/home" disableRipple>
                            Home
                        </Button>

                        <span className={classes.appspacer} />

                        <Button color="inherit" component={RouterLink} to="/help" disableRipple>
                            Help
                        </Button>

                        <Button color="inherit" component={Link} href="/studylink/connect/logout" disableRipple>
                            Sign out
                        </Button>
                    </Toolbar>
                </Paper>
            </nav>
        </div>
    );
}

export default Header;
