import React, { useState, useEffect, useRef } from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

import Container from "@material-ui/core/Container";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import { DeleteSubmission, GetAllSubmissions } from "../Shared/ApiAccess";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import ErrorDialog from "../ErrorDialog/ErrorDialog";
import step1Image from "./new-app-step1.png";
import step2Image from "./new-app-step2.png";
import step3Image from "./new-app-step3.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minWidth: "305px",
    },
    mainBody: {
        paddingTop: "24px",
        paddingBottom: "24px",
        flexGrow: "1",
    },
    startedOuter: {
        marginTop: "20px",
        marginBottom: "20px",
        textAlign: "center",
    },
    listOuter: {
        marginTop: "20px",
        marginBottom: "20px",
    },
    startedText1: {
        fontWeight: "bold",
        marginTop: "10px",
        marginBottom: "0px",
        fontSize: "1rem",
    },
    rightColumn1: {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#ffffff",
    },
    detailSeparator: {
        height: ".75rem",
    },
    card: {
        marginBottom: "8px",
        marginTop: "8px",
    },
}));

function Home() {
    const classes = useStyles();
    const history = useHistory();
    const headingRef = useRef(null);

    const [submissions, setSubmissions] = useState();
    const [activeSubmission, setActiveSubmission] = useState();
    const [openComfirmDelete, setOpenComfirmDelete] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);

    const handleClickDelete = (event, submission) => {
        setActiveSubmission(submission);
        setOpenComfirmDelete(true);
        event.stopPropagation();
    };

    const deleteDialogCallback = async (doDelete) => {
        setOpenComfirmDelete(false);

        if (doDelete) {
            try {
                await DeleteSubmission(activeSubmission);
                await LoadSubmissions();
            } catch (err) {
                setErrorMessage(err.message);
                setErrorOpen(true);
            }
        }
    };

    const formatDateTime = (dateIn, timeIn) => {
        try {
            return dateIn.substring(6, 8) + "/" + dateIn.substring(4, 6) + "/" + dateIn.substring(0, 4) + "    " + timeIn.substring(0, 5);
        } catch {
            return "";
        }
    };

    const clearErrorCallback = () => {
        setErrorOpen(false);
        if (errorMessage === "You need to log in before you can access this page") {
            history.push("/");
        }
    };

    const LoadSubmissions = async () => {
        try {
            const sub = await GetAllSubmissions();
            sub.sort(function (a, b) {
                if (a.Status === "Draft" && b.Status === "Completed") return -1;
                if (a.Status === "Completed" && b.Status === "Draft") return 1;
                if (a.DateModified + a.TimeModified > b.DateModified + b.TimeModified) return -1;
                if (a.DateModified + a.TimeModified < b.DateModified + b.TimeModified) return 1;
                return 0;
            });

            setSubmissions(sub);
        } catch (error) {
            setErrorMessage(error.message);
            setErrorOpen(true);
        }
    };

    useEffect(() => {
        LoadSubmissions();
    }, []);

    useEffect(() => {
        function sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }
        const clearTabIndex = async (control) => {
            await sleep(100);
            control.removeAttribute("tabindex");
        };

        if (headingRef.current) {
            console.log("heading ref");
            headingRef.current.focus();
            clearTabIndex(headingRef.current);
        }
    }, [submissions]);

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Helmet>
                <title>Connect with StudyLink - Home</title>
            </Helmet>

            <Header />
            <main id="main-content" className={classes.mainBody}>
                {submissions && submissions.length !== 0 && (
                    <div>
                        <Typography variant="h1" ref={headingRef} tabIndex="-1">
                            My Submissions
                        </Typography>
                        <Grid container>
                            <Grid item xs={2} sm={4} />
                            <Grid item xs={8} sm={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={RouterLink}
                                    to={{
                                        pathname: "/studentDetails/new",
                                        state: null,
                                    }}
                                    fullWidth
                                    disableRipple
                                >
                                    Get Started
                                </Button>
                            </Grid>
                        </Grid>
                        <div className={classes.listOuter}>
                            <Typography variant="body1">
                                See below all documentation that you have submitted to StudyLink. Click on ‘Get Started’ above to start a new submission.
                            </Typography>
                            <Typography variant="body1">
                                Any draft submissions that have not been edited or completed within 48 hours will be securely deleted.
                            </Typography>
                            {submissions.map((sub, index) => (
                                <Card elevation={3} key={index} className={classes.card}>
                                    <CardContent>
                                        <Grid container spacing={1}>
                                            <Grid item sm={5} xs={12}>
                                                <Typography variant="h3">Details</Typography>
                                                <Typography className={classes.heading} variant="body1">
                                                    {sub.Status}
                                                </Typography>
                                                <Typography className={classes.heading} variant="body1">
                                                    Created: {formatDateTime(sub.DateCreated, sub.TimeCreated)}
                                                </Typography>
                                                <Typography className={classes.heading} variant="body1">
                                                    {sub.Status === "Draft" ? "Modified: " : "Submitted: "} {formatDateTime(sub.DateModified, sub.TimeModified)}
                                                </Typography>
                                                <Typography className={classes.heading} variant="body1" noWrap>
                                                    {sub.FirstName + " " + sub.LastName}
                                                </Typography>
                                                <Typography className={classes.heading} variant="body1">
                                                    {sub.ClientNumber}
                                                </Typography>
                                            </Grid>
                                            {/* <Grid item sm={1} xs={0}></Grid> */}
                                            <Grid item sm={5} xs={12}>
                                                <div>
                                                    <Typography variant="h3">Documents</Typography>
                                                    {sub.UploadedFiles.map((file, index2) => (
                                                        <Typography variant="body1" key={index2} noWrap>
                                                            {file.UploadedFileName}
                                                        </Typography>
                                                    ))}
                                                </div>
                                            </Grid>
                                            {/* <Grid item sm={1} xs={0}></Grid> */}
                                            <Grid item sm={2} xs={12} hidden={sub.Status === "Completed"}>
                                                <CardActions>
                                                    <Grid container>
                                                        <Grid item sm={12} md={6}>
                                                            <Tooltip title="delete" aria-label="delete submission">
                                                                <IconButton
                                                                    aria-label="delete submission"
                                                                    color="primary"
                                                                    onClick={(e) => handleClickDelete(e, sub)}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item sm={12} md={6}>
                                                            <Tooltip title="edit" aria-label="edit submission">
                                                                <IconButton
                                                                    aria-label="edit submission"
                                                                    color="primary"
                                                                    component={RouterLink}
                                                                    to={"/studentDetails/" + sub.SubmissionId}
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </CardActions>
                                            </Grid>
                                            {/* 
                                            <Grid item xs={12} sm={5}></Grid>
                                            <Grid item xs={12} sm={2} className={classes.detailSeparator}></Grid> */}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                )}

                {submissions && submissions.length === 0 && (
                    <div>
                        <Typography variant="h1" ref={headingRef} tabIndex="-1">
                            Home
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.startedOuter}>
                                    <div>
                                        <img src={step1Image} alt="" height="189" width="189" />
                                    </div>
                                    <Typography variant="h2" className={classes.startedText1}>
                                        Provide
                                    </Typography>
                                    <Typography variant="h3">Student details</Typography>
                                    <Typography variant="body1">Provide your name, client number and email address.</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.startedOuter}>
                                    <div>
                                        <img src={step2Image} alt="" height="189" width="189" />
                                    </div>
                                    <Typography variant="h2" className={classes.startedText1}>
                                        Upload
                                    </Typography>
                                    <Typography variant="h3">To Connect</Typography>
                                    <Typography variant="body1">
                                        Scan your verified documents or take a photograph and save it. Upload the file(s) or image(s) to Connect.
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.startedOuter}>
                                    <div>
                                        <img src={step3Image} alt="" height="189" width="189" />
                                    </div>
                                    <Typography variant="h2" className={classes.startedText1}>
                                        Submit
                                    </Typography>
                                    <Typography variant="h3">To StudyLink</Typography>

                                    <Typography variant="body1">Submit your documents to StudyLink quickly and easily.</Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={2} sm={4} />
                            <Grid item xs={8} sm={4}>
                                <Button variant="contained" color="primary" component={RouterLink} to="/studentDetails/new" fullWidth>
                                    Get Started
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </main>
            {/* otherwise NVDA reads this before the contents */}
            {submissions && <Footer />}

            {/* delete confirmation */}
            <ConfirmDialog
                open={openComfirmDelete}
                confirmAction="Delete"
                confirmMessage="Are you sure you want to delete this draft submission?"
                confirmCallback={deleteDialogCallback}
            />

            <ErrorDialog errorMessage={errorMessage} open={errorOpen} clearErrorCallback={clearErrorCallback} />
        </Container>
    );
}

export default Home;
