import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";

import ClientDetails from "./ClientDetails/ClientDetails";
import UploadDocuments from "./UploadDocuments/UploadDocuments";
import Submit from "./Submit/Submit";
import Home from "./Home/Home";
import Help from "./Help/Help";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import LandingPage from "./LandingPage/LandingPage";
import IdleCheck from "./IdleCheck/IdleCheck";

import ScrollToTop from "./Shared/ScrollToTop";

ReactGA.initialize("UA-85211938-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
    return (
        <div style={{ height: "100%" }}>
            <CssBaseline />

            <Router>
                <ScrollToTop />
                <IdleCheck />
                <Switch>
                    <Route path="/studentDetails/:id">
                        <ClientDetails />
                    </Route>
                    <Route path="/upload/:id">
                        <UploadDocuments />
                    </Route>
                    <Route path="/submit/:id">
                        <Submit />
                    </Route>
                    <Route path="/help">
                        <Help />
                    </Route>
                    <Route path="/userTerms">
                        <TermsAndConditions />
                    </Route>
                    <Route path="/home">
                        <Home />
                    </Route>
 
                    <Route path="/">
                        <LandingPage />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
